import "@mdxeditor/editor/style.css";

import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CreateLink,
  ListsToggle,
  MDXEditor,
  UndoRedo,
  headingsPlugin,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  toolbarPlugin,
} from "@mdxeditor/editor";
import React, { forwardRef, useImperativeHandle, useRef } from "react";

const MarkdownEditor = forwardRef(({ data }, ref) => {
  const { value = "", onChange } = data;

  const isMobile = window.innerWidth < 800;
  let plugins = [
    headingsPlugin(),
    linkPlugin(),
    linkDialogPlugin(),
    listsPlugin(),
  ];

  if (!data.disabled) {
    plugins = [
      ...plugins,
      toolbarPlugin({
        toolbarContents: () => (
          <>
            <UndoRedo />
            <BoldItalicUnderlineToggles />
            <CreateLink />
            {!isMobile && <ListsToggle />}
            {!isMobile && <BlockTypeSelect />}
          </>
        ),
      }),
    ];
  }

  const editorRef = useRef(null);

  useImperativeHandle(ref, () => ({
    insertVariable: (variable) => {
      editorRef.current?.insertMarkdown(variable);
    },
  }));

  return (
    <MDXEditor
      ref={editorRef}
      markdown={value || ""}
      onChange={onChange}
      readOnly={data.disabled}
      plugins={plugins}
    />
  );
});

export default MarkdownEditor;
