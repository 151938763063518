import { Button, Icon, Modal, Row, Text } from "components";

import React from "react";
import { rConfirmationModalData } from "utils/recoil";
import styled from "styled-components";
import { useRecoilState } from "recoil";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
`;

const ConfirmationModal = () => {
  const [isSaving, isSavingSet] = React.useState(false);

  const [confirmationModalData, setConfirmationModalData] = useRecoilState(
    rConfirmationModalData
  );

  if (confirmationModalData) {
    const { title, text, confirm, hide, confirmText, cancelText, isFetching } =
      confirmationModalData;

    const hideModal = (e) => {
      e.stopPropagation();
      hide && hide();
      setConfirmationModalData(null);
    };

    return (
      <Modal
        hide={(e) => {
          hideModal(e);
        }}
        header={{ title }}
      >
        <Wrapper>
          <Icon
            data={{
              icon: "FiAlertCircle",
              color: "#f79e44",
              size: 50,
            }}
          />
          <Text
            data={{
              text,
              margin: "20px 0 30px 0",
              textAlign: "center",
              fontSize: 14,
            }}
          />
          <Row $gap="15px">
            {confirm && (
              <Button
                data={{
                  text: confirmText,
                  onClick: (e) => {
                    e.stopPropagation();
                    confirm(isSavingSet);
                    setConfirmationModalData(null);
                  },
                  isFetching: isFetching || isSaving,
                }}
              />
            )}
            <Button
              data={{
                text: cancelText,
                type: "basic",
                onClick: (e) => hideModal(e),
              }}
            />
          </Row>
        </Wrapper>
      </Modal>
    );
  }

  return <></>;
};

export default ConfirmationModal;
