import ReactMarkdown from "react-markdown";
import styled from "styled-components";

const StyledMarkdown = styled.div`
  color: var(--text-color);

  p {
    margin: 0.9em 0; /* Adjust margin to control spacing between paragraphs */
    line-height: 1.5;
  }

  /* Add more specific styles for headers, lists, etc., as needed */
  h1,
  h2,
  h3 {
    margin: 0.75em 0;
  }

  li {
    margin: 0.5em 0; /* Adjust margin to control spacing between list items */
    line-height: 1.4;
  }
`;

const MarkdownViewer = ({ markdown }) => {
  return (
    <StyledMarkdown>
      <ReactMarkdown>{markdown}</ReactMarkdown>
    </StyledMarkdown>
  );
};

export default MarkdownViewer;
