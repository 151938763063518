import { Form, Modal, Spinner, Table } from "components";
import { errorNotification, successNotification } from "utils/notification";
import { useEffect, useState } from "react";

import AdminWrapper from "components/AdminWrapper";
import { apiRequest } from "utils/apiRequests";
import { get } from "lodash";
import { rConfirmationModalData } from "utils/recoil";
import styled from "styled-components";
import useMixpanel from "utils/useMixpanel";
import { useSetRecoilState } from "recoil";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeUser, setActiveUser] = useState(null);
  const [savingUser, setSavingUser] = useState(false);
  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  // Track mixpanel
  const { track } = useMixpanel();
  useEffect(() => {
    track("Users");
  }, []);

  // Get users
  useEffect(() => {
    setLoading(true);
    apiRequest.get("/users/").then((res) => {
      setUsers(get(res, "data", []));
      setLoading(false);
    });
  }, []);

  // Save user
  const saveUser = () => {
    setSavingUser(true);
    apiRequest.post(`/users/`, activeUser).then((res) => {
      setSavingUser(false);

      if (activeUser.id) {
        setUsers(users.map((u) => (u.id === activeUser.id ? activeUser : u)));
      } else {
        setUsers([...users, res.data]);
      }

      successNotification("Saved");
      setActiveUser(null);
    });
  };

  // Delete user
  const deleteUser = () => {
    setSavingUser(true);
    apiRequest
      .delete(`/users/`, { data: { id: activeUser.id } })
      .then((res) => {
        const response = get(res, "data");

        const error = get(response, "error");

        if (error) {
          errorNotification(error);
        } else {
          setActiveUser(null);
          successNotification("Deleted");
          setUsers(users.filter((u) => u.id !== activeUser.id));
        }
        setSavingUser(false);
      });
  };

  let fields = [
    {
      id: "first_name",
    },
    {
      id: "last_name",
    },
    {
      id: "role",
      hint: "Define a custom role that can be used to control permissions",
      placeholder: "Admin, Editor, Project Manager, etc.",
    },
  ];

  const activeUserId = get(activeUser, "id");

  // Handle new user fields
  if (!activeUserId) {
    fields = [
      ...fields,
      {
        id: "email",
        placeholder: "email@example.com",
        hint: "The email address of the user. This will be used to login.",
      },
      {
        id: "password",
        type: "password",
        placeholder: "Password",
        hint: "The user will need this to login to sessions assigned specifically to them.",
      },
    ];
  }

  return (
    <>
      {activeUser && (
        <Modal
          minWidth="400px"
          header={{
            title: activeUserId ? `Edit User: ${activeUser.email}` : "Add User",
          }}
          hide={() => setActiveUser(null)}
        >
          <Form
            fields={fields}
            onChange={(k, v) => setActiveUser({ ...activeUser, [k]: v })}
            submit={saveUser}
            submitText="Save Changes"
            value={activeUser}
            isFetching={savingUser}
            buttons={[
              {
                text: "Delete",
                onClick: () =>
                  setConfirmationModalData({
                    title: "Delete User",
                    text: "Are you sure you want to delete this user? This is irreversible.",
                    confirmText: "Delete",
                    cancelText: "Cancel",
                    confirm: deleteUser,
                  }),
                type: "basic",
              },
            ]}
          />
        </Modal>
      )}
      <AdminWrapper
        title="Users"
        buttons={[
          {
            text: "Add User",
            size: "large",
            icon: "FiPlus",
            onClick: () => setActiveUser({}),
          },
        ]}
      >
        {loading ? (
          <Spinner />
        ) : (
          <Container>
            <Table
              data={{
                rows: users.map((u) => ({
                  ...u,
                  name: `${u.first_name} ${u.last_name}`,
                  role: u.role || "No role",
                })),
                columns: [
                  { id: "name", fontWeight: 400 },
                  { id: "email" },
                  { id: "role" },
                ],
                onRowClick: (i) => setActiveUser(get(users, i, null)),
              }}
            />
          </Container>
        )}
      </AdminWrapper>
    </>
  );
};

export default Users;

const Container = styled.div`
  background: white;
  border-radius: 10px;
  width: 100%;
  margin-top: 10px;
`;
