import {
  Breadcrumb,
  Button,
  Dropdown,
  Form,
  FormInputWrapper,
  Hint,
  Icon,
  Loader,
  Modal,
  Row,
  SelectToggle,
  Text,
} from "components";
import {
  convertJsonToSchema,
  getPixels,
  isValidJson,
  safeArray,
  sortFields,
} from "utils/utils";
import { errorNotification, successNotification } from "utils/notification";
import { get, isEmpty, startCase } from "lodash";
import { rAccount, rConfirmationModalData, rUser } from "utils/recoil";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import Cookies from "js-cookie";
import FormFields from "components/FormFields";
import HierarchyMenu from "components/HierarchyMenu";
import HierarchyVisualization from "components/HierarchyVisualization";
import { SessionContent } from "pages/Sessions/LiveSession/SessionContent";
import SetupInstructions from "./SetupInstructions";
import { apiRequest } from "utils/apiRequests";
import colors from "utils/colors";
import curatorIcon from "images/curator-icon-green.png";
import styled from "styled-components";
import useMixpanel from "utils/useMixpanel";

const SettingsSection = styled.div`
  padding: 15px;
  overflow-x: auto;
`;

const BackSection = styled.div`
  height: 60px;
  border-right: 1px solid ${colors.inputBorder};
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 15px;
  margin-right: 15px;
`;

const CuratorIcon = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

const StepDetails = () => {
  const user = useRecoilValue(rUser);
  const account = useRecoilValue(rAccount);

  const [step, setStep] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [changeObj, setChangeObj] = useState({});
  const [mockData, setMockData] = useState({});
  const [showCreateSession, setShowCreateSession] = useState(null);
  const [editActionType, setEditActionType] = useState(null);
  const [tab, setTab] = useState("basics");

  const [showSetupInstructions, setShowSetupInstructions] = useState(false);

  const automationPlatform = get(account, "automation_platform");

  const [isFetchingSlackChannels, setIsFetchingSlackChannels] = useState(false);
  const [slackChannels, setSlackChannels] = useState([]);

  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const actionData = get(step, editActionType);

  const [clearedSetupInstructionsState, setClearedSetupInstructionsState] =
    useState(false);

  const clearedSetupInstructions =
    Cookies.get("clearedSetupInstructions") || clearedSetupInstructionsState;

  const { id } = useParams();

  // Track mixpanel
  const { track } = useMixpanel();
  useEffect(() => {
    track("Step Details", {
      step_id: id,
    });
  }, [id]);

  const navigate = useNavigate();

  // Update the local state and the change object
  const updateStep = (k, v) => {
    setStep({ ...step, [k]: v });
    setChangeObj({ ...changeObj, [k]: v });
  };

  const save = async () => {
    setIsSaving(true);
    await apiRequest.post("/step/", { ...changeObj, id });
    setIsSaving(false);
    successNotification("Changes saved");
    setChangeObj({});
  };

  const deleteStep = () => {
    setIsFetching(true);
    apiRequest.delete("/step/", { data: { id } }).then(() => {
      successNotification("Step deleted");
      navigate("/admin/steps/");
      setIsFetching(false);
    });
  };

  const getStep = async () => {
    setIsFetching(true);
    const stepRes = await apiRequest.get(`/step/?id=${id}`);
    setStep(get(stepRes, "data", {}));

    // If the step has a slack location, set the slack channels
    const stepSlackLocation = get(stepRes, ["data", "slack_location"], {});
    if (stepSlackLocation) {
      setSlackChannels([stepSlackLocation]);
    }

    // Set the mock data
    setMockData(get(stepRes, ["data", "data"], {}));
    setIsFetching(false);
  };

  useEffect(() => {
    if (user && !step) {
      getStep();
    }
  }, [user]);

  const refreshData = async () => {
    setIsRefreshing(true);
    const stepRes = await apiRequest.get(`/step/?id=${id}`);
    const newStepConfig = get(stepRes, ["data", "config"], {});
    const newStepData = get(stepRes, ["data", "data"], {});
    setStep({ ...step, config: newStepConfig, data: newStepData });
    setMockData(newStepData);
    setIsRefreshing(false);
    successNotification("Data refreshed");
  };

  const config = get(step, "config", {});

  const stepFields = safeArray(step, "fields");

  const hasFields = stepFields.length > 0;

  const stepData = get(step, "data", {}) || {};

  const schema = get(convertJsonToSchema(stepData), "keys");

  const rootSorting = get(config, ["_root", "sorting"], []);

  const sortedSchema = sortFields({
    schema,
    sortingArray: rootSorting,
    objectKey: "key",
  });

  const createManualSession = async () => {
    const jsonData = get(showCreateSession, "data_json");
    if (jsonData && !isValidJson(jsonData)) {
      errorNotification("Invalid JSON");
      return;
    }

    setIsFetching(true);

    const finalData = get(showCreateSession, "data_json") || stepData;

    const newSessionData = {
      step_id: parseInt(id),
      api_key: get(account, "api_key"),
      title: get(showCreateSession, "title"),
      tags: get(showCreateSession, "tags"),
      overwrite_schema: get(showCreateSession, "overwrite_schema", false),
      data: finalData,
      user: get(showCreateSession, "for_current_user", false)
        ? get(user, "email")
        : null,
    };

    const newSession = await apiRequest.post(
      "/create_session/",
      newSessionData
    );
    const res = get(newSession, "data", {});
    setIsFetching(false);
    successNotification("Session created");

    const sessionLink = get(res, "session_link");
    window.open(sessionLink, "_blank");
  };

  let dropdownOptions = [
    {
      label: "Delete Step",
      icon: "FiTrash",
      onClick: () =>
        setConfirmationModalData({
          title: "Delete Step",
          text: "Are you sure you want to delete this step? This is irreversible.",
          confirmText: "Delete",
          cancelText: "Cancel",
          confirm: deleteStep,
        }),
    },
  ];

  if (stepData) {
    dropdownOptions = [
      ...dropdownOptions,
      {
        label: "Create Session Manually",
        icon: "FiPlus",
        onClick: () => setShowCreateSession({}),
      },
    ];
  }

  const confirmAction = get(step, "confirm_action");
  const denyAction = get(step, "deny_action");

  const hasAction = confirmAction || denyAction;

  const slackIntegrated = get(account, "slack_integrated", false);

  const slackLocation = get(step, "slack_location");

  // Fetching
  if (isFetching) {
    return <Loader />;
  }

  const variableFields = hasFields
    ? []
    : [
        {
          id: "data_json",
          label: "Data",
          componentId: "TextArea",
          monospace: true,
          placeholder: "Data",
          minHeight: "200px",
          hint: "The data to be used in the session. Leave blank to use the existing cached data.",
          value: get(showCreateSession, "data_json"),
        },
        {
          id: "overwrite_schema",
          label: "Overwrite Schema",
          componentId: "Switch",
          hint: "If checked, the schema will be overwritten with the new data",
          value: get(showCreateSession, "overwrite_schema", false),
        },
      ];

  // Render content
  return (
    <>
      {showSetupInstructions && (
        <Modal
          minWidth="800px"
          header={{
            title: "Setup Instructions",
          }}
          hide={() => setShowSetupInstructions(false)}
        >
          <SetupInstructions fields={safeArray(step, "fields")} />
        </Modal>
      )}
      {showCreateSession && (
        <Modal
          minWidth="600px"
          header={{
            title: "Create Session Manually",
            description:
              "This feature makes testing your flows easier, since you can start with a valid session and test the review and submission process.",
          }}
          hide={() => setShowCreateSession(null)}
        >
          <Form
            fields={[
              {
                id: "title",
                label: "Session Title",
                componentId: "Input",
                placeholder: "Session Title",
                hint: "The visual label the user sees at the top of the session",
                value: get(showCreateSession, "title"),
              },
              {
                id: "for_current_user",
                label: "For Current User",
                componentId: "Switch",
                hint: "If checked, the session will be created for the current user",
                value: get(showCreateSession, "for_current_user", false),
              },
              {
                id: "tags",
                label: "Tags",
                componentId: "Input",
                placeholder: "Marketing, Admin, etc",
                hint: "A comma-separated list of tags, used to organize sessions in the dashboard",
                value: get(showCreateSession, "tags"),
              },
              ...variableFields,
            ]}
            onChange={(k, v) =>
              setShowCreateSession({ ...showCreateSession, [k]: v })
            }
            submit={createManualSession}
            submitText="Create Session"
            value={showCreateSession}
            // isFetching={savingUser}
          />
        </Modal>
      )}
      <AppContainer>
        <Header>
          <BackSection>
            <CuratorIcon src={curatorIcon} onClick={() => navigate("/steps")} />
          </BackSection>
          <StyledInput
            value={get(step, "name")}
            onChange={(e) => updateStep("name", e.target.value)}
            placeholder={"New Step"}
          />

          <Row $alignitems="center" $justifycontent="space-between" $gap="15px">
            <Dropdown
              data={{
                icon: {
                  icon: "FiMoreHorizontal",
                  size: 25,
                  color: colors.grey,
                  margin: "3px 15px 0 0",
                  hover: true,
                },
                options: dropdownOptions,
              }}
            />
            {[
              {
                text: "View Setup Instructions",
                onClick: () => setShowSetupInstructions(true),
                icon: "FiHelpCircle",
                type: "basic",
              },
              {
                text: "Refresh Data",
                onClick: refreshData,
                icon: "FiRefreshCcw",
                type: "basic",
                isFetching: isRefreshing,
              },
              {
                text: "Save Changes",
                onClick: save,
                disabled: isEmpty(changeObj),
                isFetching: isSaving,
              },
            ].map((b) => (
              <Button data={b} style={{ fontSize: "30px" }} />
            ))}
          </Row>
        </Header>
        <Sidebar>
          {editActionType && (
            <SettingsSection
              style={{ borderBottom: `1px solid ${colors.inputBorder}` }}
            >
              <Breadcrumb
                fontSize={18}
                fontWeight={500}
                margin="0 0 20px 0"
                items={[
                  {
                    text: "Settings",
                    onClick: () => setEditActionType(null),
                  },
                  {
                    text: startCase(editActionType),
                    onClick: null,
                  },
                ]}
              />
              <Form
                onChange={(k, v) =>
                  updateStep(editActionType, { ...actionData, [k]: v })
                }
                fields={[
                  {
                    id: "label",
                    label: "Button Label",
                    componentId: "Input",
                    placeholder: "Confirm",
                    value: get(actionData, "label"),
                    hint: "The text to display on the button",
                  },
                  {
                    id: "endpoint",
                    label: "Endpoint",
                    componentId: "Input",
                    placeholder: "https://your-endpoint.com/response/",
                    value: get(actionData, "endpoint"),
                    hint: "The endpoint where the final data will be sent upon submission",
                    displayCondition: () => automationPlatform !== "Zapier",
                  },
                  {
                    id: "redirect_type",
                    label: "Redirect Type",
                    componentId: "Select",
                    value: get(actionData, "redirect_type"),
                    defaultValue: "url",
                    hint: "Determines where the user will be redirected after they submit",
                    options: [
                      { label: "Dashboard", value: "dashboard" },
                      { label: "Message", value: "message" },
                      { label: "URL", value: "url" },
                    ],
                  },
                  {
                    id: "redirect_url",
                    label: "Redirect URL",
                    componentId: "Input",
                    placeholder: "https://example.com",
                    value: get(actionData, "redirect_url"),
                    hint: "The URL to redirect to after the user submits",
                    displayCondition: () =>
                      get(actionData, "redirect_type") === "url" ||
                      !get(actionData, "redirect_type"),
                  },
                  {
                    id: "redirect_message",
                    label: "Redirect Message",
                    componentId: "Input",
                    placeholder: "Your response has been submitted",
                    value: get(actionData, "redirect_message"),
                    hint: "The message to display to the user after they submit",
                    displayCondition: () =>
                      get(actionData, "redirect_type") === "message",
                  },
                  {
                    id: "clear",
                    label: "Clear Action Data (Reset)",
                    text: `Clear ${startCase(editActionType)}`,
                    type: "basic",
                    icon: "FiTrash",
                    componentId: "Button",
                    onClick: () => {
                      setConfirmationModalData({
                        title: `Clear ${startCase(editActionType)}`,
                        text: "Are you sure you want to clear this action?",
                        confirmText: "Clear",
                        cancelText: "Cancel",
                        confirm: () => {
                          setEditActionType(null);
                          updateStep(editActionType, null);
                        },
                      });
                    },
                    displayCondition: () => !!actionData,
                  },
                ].filter(
                  (f) =>
                    !f.displayCondition ||
                    (f.displayCondition && f.displayCondition())
                )}
              />
            </SettingsSection>
          )}

          <HierarchyMenu
            originalData={stepData}
            config={config}
            onChange={(newConfig) => updateStep("config", newConfig)}
          />

          {!editActionType && (
            <SelectToggle
              data={{
                margin: "15px 15px 0 15px",
                onChange: (v) => setTab(v),
                tabs: [
                  {
                    label: "Basics",
                    value: "basics",
                    active: !tab || tab === "basics",
                  },
                  {
                    label: "Flagging",
                    value: "flagging",
                    active: tab === "flagging",
                  },
                  {
                    label: "Advanced",
                    value: "advanced",
                    active: tab === "advanced",
                  },
                ],
              }}
            />
          )}

          {tab === "flagging" && (
            <SettingsSection>
              <TitleHint
                title="Flagging"
                hint="Define the rules that determine when a session is flagged for approval."
              />
              <Form
                onChange={(k, v) =>
                  updateStep("flagging_rules", {
                    ...get(step, "flagging_rules", {}),
                    [k]: v,
                  })
                }
                fields={[
                  {
                    id: "flag_type",
                    label: "Flag Type",
                    componentId: "Select",
                    hint: "The type of flagging system to use.",
                    value: get(step, ["flagging_rules", "flag_type"], "all"),
                    options: [
                      { label: "All Sessions", value: "all" },
                      {
                        label: "AI Prompt",
                        value: "ai_prompt",
                      },
                      {
                        label: "Percentage",
                        value: "percentage",
                      },
                    ],
                  },
                  {
                    id: "flag_percentage",
                    label: "Flag Percentage",
                    componentId: "Input",
                    value: get(
                      step,
                      ["flagging_rules", "flag_percentage"],
                      100
                    ),
                    min: 1,
                    max: 100,
                    type: "number",
                    hint: "The percentage of sessions to flag",
                    displayCondition: () =>
                      get(step, ["flagging_rules", "flag_type"]) ===
                      "percentage",
                  },
                  {
                    id: "flag_prompt",
                    label: "Flag Prompt",
                    componentId: "TextArea",
                    minHeight: "200px",
                    value: get(step, ["flagging_rules", "flag_prompt"]),
                    hint: "The prompt to use to flag sessions",
                    displayCondition: () =>
                      get(step, ["flagging_rules", "flag_type"]) ===
                      "ai_prompt",
                  },
                ].filter(
                  (f) =>
                    !f.displayCondition ||
                    (f.displayCondition && f.displayCondition())
                )}
              />
            </SettingsSection>
          )}

          {tab === "advanced" && (
            <>
              <SettingsSection
                style={{ borderBottom: `1px solid ${colors.inputBorder}` }}
              >
                <Text
                  data={{
                    text: "Styling",
                    fontSize: 18,
                    fontWeight: 500,
                    margin: "0 0 20px 0",
                  }}
                />

                <Form
                  onChange={(k, v) => updateStep(k, v)}
                  fields={[
                    {
                      id: "pageWidth",
                      label: "Page Width",
                      componentId: "Select",
                      hint: "The maximum width of the page content. Can be overridden on a per-session basis.",
                      options: [
                        { label: "600px", value: 600 },
                        { label: "800px", value: 800 },
                        { label: "1000px", value: 1000 },
                        { label: "1200px", value: 1200 },
                      ],
                      value: get(step, "pageWidth", 800),
                    },
                  ]}
                />
              </SettingsSection>
              {!hasFields && (
                <SettingsSection>
                  <TitleHint
                    title="Data Structure"
                    hint="Review the data structure and adjust the visualization types as needed."
                  />

                  {sortedSchema.length > 0 && (
                    <HierarchyVisualization
                      originalData={stepData}
                      data={sortedSchema}
                      config={config}
                      onChange={(newConfig) => updateStep("config", newConfig)}
                    />
                  )}
                  {!sortedSchema.length && (
                    <Text
                      data={{
                        text: "No data structure has been established",
                        fontSize: 16,
                        fontWeight: 300,
                        margin: "5px 0 0 0",
                      }}
                    />
                  )}
                </SettingsSection>
              )}
            </>
          )}

          {tab === "basics" && !editActionType && (
            <>
              <SettingsSection
                style={{ borderBottom: `1px solid ${colors.inputBorder}` }}
              >
                <Text
                  data={{
                    text: "Slack Notifications",
                    fontSize: 18,
                    fontWeight: 500,
                    margin: "0 0 15px 0",
                  }}
                />
                {!slackIntegrated && (
                  <>
                    <Text
                      data={{
                        text: "Connect your Slack account to enable this feature",
                        fontSize: 14,
                        fontWeight: 300,
                        margin: "0 0 15px 0",
                      }}
                    />
                    <Text
                      data={{
                        text: "Go to Settings",
                        fontSize: 14,
                        fontWeight: 600,
                        color: colors.primary,
                        onClick: () => navigate("/admin/settings"),
                      }}
                    />
                  </>
                )}
                {slackIntegrated && (
                  <>
                    {slackChannels.length < 2 && (
                      <Button
                        data={{
                          text: slackLocation
                            ? "Change Slack Channel"
                            : "Select Slack Channel",
                          isFetching: isFetchingSlackChannels,
                          type: "basic",
                          icon: "FiSlack",
                          onClick: () => {
                            setIsFetchingSlackChannels(true);
                            apiRequest.get("slack/channels/").then((res) => {
                              const channels = get(
                                res,
                                ["data", "channels"],
                                []
                              );
                              setSlackChannels(channels);
                              setIsFetchingSlackChannels(false);
                            });
                          },
                        }}
                      />
                    )}
                    {(slackLocation || slackChannels.length > 0) && (
                      <Form
                        margin="10px 0 0 0"
                        onChange={(k, v) => {
                          const matchingChannel = slackChannels.find(
                            (c) => c.id === v
                          );
                          updateStep(k, matchingChannel);
                        }}
                        fields={[
                          {
                            id: "slack_location",
                            label: "Slack Channel",
                            componentId: "Select",
                            options: slackChannels.map((c) => ({
                              label: c.name,
                              value: c.id,
                            })),
                            value: get(slackLocation, "id"),
                          },
                        ]}
                      />
                    )}
                  </>
                )}
              </SettingsSection>
              <SettingsSection
                style={{ borderBottom: `1px solid ${colors.inputBorder}` }}
              >
                <Text
                  data={{
                    text: "Fields",
                    fontSize: 18,
                    fontWeight: 500,
                    margin: "0 0 15px 0",
                  }}
                />
                <FormFields
                  fields={safeArray(step, "fields")}
                  setFields={(f) => updateStep("fields", f)}
                />
              </SettingsSection>
              <SettingsSection
                style={{ borderBottom: `1px solid ${colors.inputBorder}` }}
              >
                <Text
                  data={{
                    text: "Actions",
                    fontSize: 18,
                    fontWeight: 500,
                    margin: "0 0 20px 0",
                  }}
                />

                <FormInputWrapper
                  label="Confirm Action"
                  hint="Configure the action the user will take when they confirm their data."
                >
                  <Button
                    data={{
                      text: "Configure Confirm Action",
                      type: "basic",
                      icon: "FiSettings",
                      onClick: () => setEditActionType("confirm_action"),
                      margin: "0 0 20px 0",
                    }}
                  />
                </FormInputWrapper>
                <FormInputWrapper
                  label="Deny Action"
                  hint="Configure the action the user will take when they deny their data."
                >
                  <Button
                    data={{
                      text: "Configure Deny Action",
                      type: "basic",
                      icon: "FiSettings",
                      onClick: () => setEditActionType("deny_action"),
                      margin: "0 0 5px 0",
                    }}
                  />
                </FormInputWrapper>
                {!hasAction && automationPlatform !== "Zapier" && (
                  <WarningBubble text="Configure actions to complete setup" />
                )}
              </SettingsSection>
            </>
          )}
        </Sidebar>
        <Main>
          <MainContent $maxWidth={get(step, "pageWidth", 800)}>
            <SessionContent
              isAdmin={true}
              step={step}
              updateStep={updateStep}
              session={{ title: get(step, "title") }}
              sessionData={mockData}
              setSession={(d) => setMockData(get(d, "data"))}
              stepConfig={config}
              schema={schema}
              sessionId={id}
            />

            {(!clearedSetupInstructions ||
              (isEmpty(stepData) && !hasFields)) && (
              <div>
                <Row $margin="40px 0 20px 0" $alignitems="center" $gap="10px">
                  <Text
                    data={{
                      text: "Setup Instructions",
                      fontSize: 18,
                      fontWeight: 500,
                    }}
                  />
                  {!clearedSetupInstructions && (
                    <Text
                      data={{
                        text: "Click to hide",
                        fontSize: 16,
                        color: colors.primary,
                        fontWeight: 300,
                        onClick: () => {
                          Cookies.set("clearedSetupInstructions", true);
                          setClearedSetupInstructionsState(true);
                        },
                      }}
                    />
                  )}
                </Row>
                <SetupInstructions fields={safeArray(step, "fields")} />
              </div>
            )}
          </MainContent>
        </Main>
      </AppContainer>
    </>
  );
};

export default StepDetails;

const StyledInput = styled.input`
  font-size: 26px;
  font-weight: 700;
  color: ${colors.darkGrey};
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  width: 100%;
`;

const AppContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  border-bottom: 1px solid ${colors.inputBorder};
  padding: 0 15px 0 15px;
`;

const Sidebar = styled.div`
  width: 345px;
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  border-right: 1px solid ${colors.inputBorder};
  overflow-y: auto;
`;

const Main = styled.div`
  position: fixed;
  top: 60px;
  left: 345px;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  background: var(--live-background);
`;

const MainContent = styled.div`
  max-width: ${(p) => getPixels(p.$maxWidth || 800)};
  margin: 0 auto;
  border-radius: 10px;
  padding: 30px;
`;

const TitleHint = ({ title, hint, margin = "0 0 20px 0" }) => (
  <Row $margin={margin} $alignitems="center" $gap="5px">
    <Text
      data={{
        text: title,
        fontSize: 18,
        fontWeight: 500,
      }}
    />
    <Hint hint={hint} margin="5px 0 0 0" />
  </Row>
);

const WarningBubble = ({ text, margin = "20px 0 0 0" }) => (
  <WarningBubbleBackground $margin={margin}>
    <Icon
      data={{
        icon: "FiAlertTriangle",
        size: 20,
        color: "#df516e",
      }}
    />
    <Text data={{ text, fontSize: 14 }} />
  </WarningBubbleBackground>
);

const WarningBubbleBackground = styled.div`
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid ${colors.inputBorder};
  margin: ${(p) => p.$margin};
`;
