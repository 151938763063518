import styled from "styled-components";

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(p) => p.$alignitems || "normal"};
  justify-content: ${(p) => p.$justifycontent || "flex-start"};
  width: ${(p) => p.width || "100%"};
  gap: ${(p) => p.$gap || "0px"};
  margin: ${(p) => p.$margin || "0px"};
`;

export default Column;
