import { Column, Tabs } from "components";
import { Container, Draggable } from "@edorivai/react-smooth-dnd";
import { arrayMove, getType, sortFields } from "utils/utils";
import { get, startCase } from "lodash";

import colors from "utils/colors";
import { rEditorState } from "utils/recoil";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { useState } from "react";

const ItemContainer = styled.div`
  margin-left: 20px;
  border: 1px solid ${colors.inputBorder};
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  width: fit-content;
  cursor: pointer;
  min-width: 200px;
  background: white;
  ${(p) => p.$active && `outline: 2px solid ${colors.primary};`}
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

const Key = styled.div`
  font-weight: bold;
`;

const Type = styled.div`
  background: ${(props) => {
    switch (props.type) {
      case "string":
        return "#429ef040";
      case "number":
        return "#74c98f40";
      case "boolean":
        return "#f79e4440";
      case "object":
        return "#6945e140";
      case "array":
        return "#f7c74440";
      default:
        return colors.grey2;
    }
  }};
  padding: 4px 6px;
  border-radius: 6px;
  font-size: 13px;
  width: fit-content;
`;

const Structure = styled.pre`
  font-family: monospace;
  padding: 15px;
  background: var(--input-background);
  border-radius: 5px;
  font-size: 16px;
`;

const HierarchyVisualization = ({ originalData, config, onChange }) => {
  const [editorState, setEditorState] = useRecoilState(rEditorState);
  const [tab, setTab] = useState("visual");

  const renderHierarchy = ({
    obj,
    level = 0,
    path = [],
    displayKey = "",
    isArray = false,
  }) => {
    const objType = getType(obj);
    let currentLevel = isArray ? level - 1 : level;

    let displayType = objType;
    if (objType === "array") {
      const firstChild = get(obj, 0);
      if (getType(firstChild) === "object") {
        displayType = "Object Array";
      }
    }

    const pathJoined = path.filter((p) => p !== 0).join(".");
    const finalPath = pathJoined === "" ? "_root" : pathJoined;
    const pathConfig = get(config, finalPath, {});
    const fieldSorting = get(pathConfig, "sorting", []);
    const objectKeys =
      objType === "object"
        ? sortFields({ schema: Object.keys(obj), sortingArray: fieldSorting })
        : [];

    return (
      <div>
        {level !== 0 && !isArray && (
          <ItemContainer
            style={{ marginLeft: `${(currentLevel - 1) * 40}px` }}
            onClick={(event) => {
              setEditorState({
                ...editorState,
                activePath: path,
                anchorElement: event.currentTarget,
              });
            }}
          >
            <Item>
              <Key>{displayKey}</Key>
              <Type type={objType}>{startCase(displayType)}</Type>
            </Item>
          </ItemContainer>
        )}

        {objType === "object" && (
          <Container
            style={{ display: "flex", flexDirection: "column" }}
            dragHandleSelector=".drag-item"
            lockAxis="y"
            onDrop={(e) => {
              const { addedIndex, removedIndex } = e;
              const movedItems = arrayMove(
                objectKeys,
                removedIndex,
                addedIndex
              );
              onChange({
                ...config,
                [finalPath]: {
                  ...pathConfig,
                  sorting: movedItems,
                },
              });
            }}
          >
            {objectKeys.map((key) => (
              <Draggable key={key}>
                <div className="drag-item">
                  {renderHierarchy({
                    obj: obj[key],
                    level: currentLevel + 1,
                    path: [...path, key],
                    displayKey: key,
                  })}
                </div>
              </Draggable>
            ))}
          </Container>
        )}

        {objType === "array" && (
          <Column>
            {renderHierarchy({
              obj: get(obj, 0),
              level: currentLevel + 1,
              path: [...path, 0],
              isArray: true,
            })}
          </Column>
        )}
      </div>
    );
  };

  return (
    <div>
      <Tabs
        data={{
          margin: "0 0 20px 0",
          tabs: [
            {
              label: "Visual",
              active: tab === "visual",
              onClick: () => setTab("visual"),
            },
            {
              label: "JSON Preview",
              active: tab === "json",
              onClick: () => setTab("json"),
            },
          ],
        }}
      />

      {tab === "visual" &&
        renderHierarchy({ obj: originalData, level: 0, path: [] })}
      {tab === "json" && (
        <Structure>{JSON.stringify(originalData, null, 2)}</Structure>
      )}
    </div>
  );
};

export default HierarchyVisualization;
