import {
  Breadcrumb,
  Button,
  EditableText,
  Hint,
  Icon,
  Input,
  List,
  MarkdownEditor,
  Row,
  Select,
  Switch,
  Table,
  Text,
  TextArea,
} from "components";
import { Container, Draggable } from "@edorivai/react-smooth-dnd";
import { arrayMove, getPixels, safeArray, sortFields } from "utils/utils";
import { get, set, startCase } from "lodash";
import { rEditorState, rHoverPath } from "utils/recoil";
import { useRecoilState, useSetRecoilState } from "recoil";

import styled from "styled-components";
import { useState } from "react";

const HierarchyEditor = ({
  schema,
  data: initialData,
  onChange,
  config,
  isAdmin,
  activeDataPath,
  setActiveDataPath,
}) => {
  const [activeSchemaPath, setActiveSchemaPath] = useState([]);
  const [hoverPath, setHoverPath] = useRecoilState(rHoverPath);

  const activeData = get(initialData, activeDataPath, null);
  const activeSchema = get(schema, activeSchemaPath, null);
  const data = activeData || initialData;

  const setEditorState = useSetRecoilState(rEditorState);

  const handleChange = (path, value, isDeleting = false) => {
    let finalPath = activeData ? [...activeDataPath, ...path] : path;

    // Adjust finalPath if deleting
    if (isDeleting) {
      finalPath = path;
    }

    const newData = { ...initialData };
    set(newData, finalPath, value);
    onChange(newData);
  };

  const getFieldConfigAndData = ({ schemaItem, dataPath, configPath }) => {
    // Determine a consistent config path
    // If we have an activeDataPath, we try to construct from it,
    // else we fall back to configPath directly.
    const activePrefix = safeArray(activeDataPath).filter((p) => p !== 0);
    const fieldDataPath =
      activePrefix.length > 0
        ? [...activePrefix, ...dataPath].join(".")
        : configPath.join(".");

    const fieldConfig = get(config, fieldDataPath, {});
    const componentId = get(fieldConfig, "componentId", "Input");
    const placeholder = get(fieldConfig, "placeholder", "");
    const fieldType = get(schemaItem, "type", "string");

    const value = get(data, dataPath, fieldType === "number" ? 0 : "");

    return {
      fieldConfig,
      componentId,
      placeholder,
      fieldType,
      value,
      fieldDataPath,
    };
  };

  const renderComponentField = (
    componentId,
    { schemaItem, dataPath, fieldConfig, placeholder, value }
  ) => {
    const onValueChange = (v) => handleChange(dataPath, v);

    switch (componentId) {
      case "ViewOnly":
        return <Text data={{ text: value }} />;
      case "MarkdownEditor":
        return <MarkdownEditor data={{ value, onChange: onValueChange }} />;
      case "Label":
        return (
          <EditableText
            editable
            multiline
            fontSize={18}
            fontWeight={600}
            width="100%"
            placeholder={placeholder}
            value={value}
            onChange={onValueChange}
          />
        );
      case "Image":
        return (
          <Image
            objectFit={get(fieldConfig, "objectFit", "cover")}
            height={get(fieldConfig, "height", "150px")}
            width={get(fieldConfig, "width", "300px")}
            src={value}
          />
        );
      case "Select":
        return (
          <Select
            data={{
              options: get(fieldConfig, "options", []),
              placeholder: placeholder || "Enter text",
              width: "100%",
              value,
              onChange: onValueChange,
            }}
          />
        );
      case "TextArea":
        return (
          <TextArea
            data={{
              minheight: get(fieldConfig, "height", "100px"),
              placeholder: placeholder || "Enter text",
              value,
              onChange: onValueChange,
            }}
          />
        );
      case "Switch":
        return <Switch data={{ value: !!value, onChange: onValueChange }} />;
      default:
        return null;
    }
  };

  const renderFieldByType = (
    fieldType,
    {
      dataPath,
      fieldConfig,
      placeholder,
      value,
      schemaItem,
      schemaPath,
      configPath,
    }
  ) => {
    const onValueChange = (v) => handleChange(dataPath, v);

    switch (fieldType) {
      case "string":
        return (
          <Input
            data={{
              placeholder: placeholder || "Enter text",
              width: "100%",
              value,
              disabled: get(fieldConfig, "disabled", false),
              onChange: onValueChange,
            }}
          />
        );
      case "number":
        return (
          <Input
            data={{
              placeholder: placeholder || "Enter number",
              value,
              type: "number",
              disabled: get(fieldConfig, "disabled", false),
              onChange: (v) => onValueChange(parseFloat(v)),
            }}
          />
        );
      case "boolean":
        return <Switch data={{ value: !!value, onChange: onValueChange }} />;
      case "array":
        return renderArrayField({
          schemaItem,
          dataPath,
          fieldConfig,
          schemaPath,
          configPath,
        });
      case "object":
        return renderObjectField({
          schemaItem,
          dataPath,
          fieldConfig,
          schemaPath,
          configPath,
        });
      default:
        return null;
    }
  };

  const renderArrayField = ({
    schemaItem,
    dataPath,
    fieldConfig,
    schemaPath,
    configPath,
  }) => {
    const arrayType = get(schemaItem, "array_type", "string");
    const arrayItems = safeArray(data, dataPath);
    const subSchemaItem = get(schemaItem, "keys", []);
    const addItem = () => {
      let newObj = {};
      subSchemaItem.forEach((item) => {
        newObj[item.key] = "";
      });
      handleChange(dataPath, [
        ...arrayItems,
        arrayType === "object" ? schemaItem.defaultItem || newObj : "",
      ]);
    };

    const addButton = (
      <Button
        data={{
          text: "Add Item",
          type: "basic",
          size: "small",
          icon: "FiPlus",
          margin: "10px 0 0 0",
          onClick: addItem,
        }}
      />
    );

    const componentId = get(fieldConfig, "componentId", "Input");
    if (
      arrayType === "object" &&
      (componentId === "Table" || componentId === "List")
    ) {
      return renderSpecializedArray({
        arrayItems,
        dataPath,
        schemaPath,
        fieldConfig,
        addButton,
        componentId,
      });
    }

    // Default array rendering (draggable list)
    return (
      <div>
        <Container
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          dragHandleSelector=".drag-item"
          lockAxis="y"
          onDrop={(e) => {
            const { addedIndex, removedIndex } = e;
            const movedItems = arrayMove(arrayItems, removedIndex, addedIndex);
            handleChange(dataPath, movedItems);
          }}
        >
          {arrayItems.map((element, index) => (
            <Draggable key={index}>
              <Row
                className="drag-item"
                $alignitems="center"
                $gap="2px"
                style={{ position: "relative", borderRadius: "10px" }}
              >
                {arrayType === "object" ? (
                  renderField({
                    schemaItem: { type: "object", keys: subSchemaItem },
                    schemaPath,
                    dataPath: [...dataPath, index],
                    configPath,
                  })
                ) : (
                  <Input
                    data={{
                      placeholder: schemaItem.placeholder || "Enter text",
                      width: "100%",
                      value: element,
                      onChange: (v) => {
                        const newArrayItems = [...arrayItems];
                        newArrayItems[index] = v;
                        handleChange(dataPath, newArrayItems);
                      },
                    }}
                  />
                )}

                <div
                  style={{ position: "absolute", top: "10px", right: "10px" }}
                >
                  <Icon
                    data={{
                      icon: "FiX",
                      size: 20,
                      color: "var(--dark-grey)",
                      margin: "2px 0 0 0",
                      hover: true,
                      onClick: () => {
                        handleChange(
                          dataPath,
                          arrayItems.filter((_, i) => i !== index)
                        );
                      },
                    }}
                  />
                </div>
              </Row>
            </Draggable>
          ))}
          {addButton}
        </Container>
      </div>
    );
  };

  const renderSpecializedArray = ({
    arrayItems,
    fieldConfig,
    dataPath,
    schemaPath,
    addButton,
    componentId,
  }) => {
    if (componentId === "Table") {
      const columnSorting = get(fieldConfig, "sorting", []);
      const firstRow = get(arrayItems, 0, {});
      const columns = Object.keys(firstRow).map((key) => {
        const columnConfig = get(config, `${dataPath.join(".")}.${key}`, {});
        return {
          id: key,
          name: startCase(key),
          ...columnConfig,
        };
      });
      const sortedColumns = sortFields({
        schema: columns,
        sortingArray: columnSorting,
        objectKey: "id",
      });

      return (
        <div>
          <Table
            data={{
              columns: sortedColumns,
              rows: arrayItems,
              onRowClick: (itemIndex) => {
                setActiveDataPath([...dataPath, itemIndex]);
                setActiveSchemaPath([...schemaPath]);
              },
            }}
          />
          {addButton}
        </div>
      );
    }

    if (componentId === "List") {
      return (
        <div>
          <List
            data={{
              items: arrayItems,
              onItemClick: (itemIndex) => {
                setActiveDataPath([...dataPath, itemIndex]);
                setActiveSchemaPath([...schemaPath]);
              },
            }}
          />
          {addButton}
        </div>
      );
    }

    return null;
  };

  const renderObjectField = ({
    schemaItem,
    dataPath,
    fieldConfig,
    schemaPath,
    configPath,
  }) => {
    const schemaKeys = get(schemaItem, "keys", []);
    const objectLayout = get(fieldConfig, "layout", "list");
    const hideCard = get(fieldConfig, "hideCard", false);
    const fieldSorting = get(fieldConfig, "sorting", []);
    const sortedSchemaKeys = sortFields({
      schema: schemaKeys,
      sortingArray: fieldSorting,
      objectKey: "key",
    });

    const children = sortedSchemaKeys.map((keyItem, index) => {
      const newSchemaPath = [...schemaPath, "keys", index];
      const newDataPath = [...dataPath, keyItem.key];
      const objectKeyPath = [...configPath, keyItem.key];
      const objectKeyConfig = get(config, objectKeyPath.join("."), {});
      const itemType = get(objectKeyConfig, "type", "string");
      const columnSpan = get(objectKeyConfig, "columnSpan", 1);
      const forceFullWidth =
        itemType.includes("array") || itemType.includes("object");

      return (
        <ObjKey
          key={index}
          $forceFullWidth={forceFullWidth}
          $columnSpan={columnSpan}
          $active={isAdmin && hoverPath === newDataPath.join(".")}
          onClick={(e) => {
            e.stopPropagation();
            setEditorState({
              activePath: newDataPath,
              anchorElement: e.currentTarget,
              showAdvanced: false,
            });
          }}
          onMouseOver={(event) => handleMouseOver(newDataPath.join("."), event)}
          onMouseLeave={handleMouseLeave}
        >
          {!get(objectKeyConfig, "hideCard") && (
            <Text
              data={{
                text: startCase(keyItem.key),
                fontSize: hideCard ? 18 : 16,
                fontWeight: 600,
                margin: "0 0 10px 0",
                cursor: isAdmin ? "pointer" : "default",
              }}
            />
          )}
          {renderField({
            schemaItem: keyItem,
            schemaPath: newSchemaPath,
            dataPath: newDataPath,
            configPath: objectKeyPath,
          })}
        </ObjKey>
      );
    });

    const gridItemWidth = get(fieldConfig, "gridItemWidth", 250);

    return (
      <ObjectContainer
        $gridItemWidth={gridItemWidth}
        gridLayout={objectLayout === "grid"}
        $hidecard={hideCard}
        $active={isAdmin && hoverPath === safeArray(dataPath).join(".")}
        onMouseOver={(event) =>
          handleMouseOver(safeArray(dataPath).join("."), event)
        }
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </ObjectContainer>
    );
  };

  const renderField = ({
    schemaItem,
    schemaPath,
    dataPath,
    configPath = [],
  }) => {
    const { fieldConfig, componentId, placeholder, fieldType, value } =
      getFieldConfigAndData({ schemaItem, dataPath, configPath });

    // If a specific componentId is given (like MarkdownEditor, Label, etc.)
    // try to render that first. Otherwise, fallback to type-based rendering.
    const rendered = renderComponentField(componentId, {
      schemaItem,
      dataPath,
      fieldConfig,
      placeholder,
      value,
    });
    if (rendered) {
      return rendered;
    }

    // If no specialized component or it's a known type (string, number, boolean, array, object)
    return renderFieldByType(fieldType, {
      dataPath,
      fieldConfig,
      placeholder,
      value,
      schemaItem,
      schemaPath,
      configPath,
    });
  };

  const activeSchemaKeys = activeSchema
    ? get(activeSchema, "keys", [])
    : schema;
  const activeDataPathJoined =
    safeArray(activeDataPath).length > 0
      ? activeDataPath.filter((p) => p !== 0).join(".")
      : "_root";

  const activeRootConfig = get(config, activeDataPathJoined, {});
  const fieldSorting = get(activeRootConfig, "sorting", []);
  const sortedSchemaKeys = sortFields({
    schema: activeSchemaKeys,
    sortingArray: fieldSorting,
    objectKey: "key",
  });

  const dataPathFiltered = safeArray(activeDataPath).filter(
    (p) => typeof p !== "number"
  );
  const activeItemKey = get(
    dataPathFiltered,
    dataPathFiltered.length - 1,
    null
  );
  const activeItemCount = activeDataPath
    ? get(activeDataPath, activeDataPath.length - 1, 0) + 1
    : null;

  const rootLayout = get(activeRootConfig, "layout", "list");

  const handleMouseOver = (path, event) => {
    event.stopPropagation();
    setHoverPath(path);
  };

  const handleMouseLeave = () => {
    setHoverPath(null);
  };

  const gridItemWidth = get(activeRootConfig, "gridItemWidth", 250);

  return (
    <MainContainer>
      {activeSchema && (
        <Row>
          <Breadcrumb
            fontSize={20}
            items={[
              {
                text: startCase(activeItemKey),
                onClick: (e) => {
                  e.stopPropagation();
                  setActiveDataPath(null);
                  setActiveSchemaPath(null);
                },
              },
              {
                text: activeItemCount,
              },
            ]}
          />
        </Row>
      )}

      <ObjectContainer
        gridLayout={rootLayout === "grid"}
        $hidecard={true}
        $gridItemWidth={gridItemWidth}
      >
        {sortedSchemaKeys.map((schemaItem, index) => {
          const dataPath =
            safeArray(activeDataPath).length > 0
              ? [activeDataPath.filter((p) => p !== 0), schemaItem.key].join(
                  "."
                )
              : schemaItem.key;

          const itemConfig = get(config, dataPath, {});

          const hideCard = get(itemConfig, "hideCard", false);
          const label = get(itemConfig, "label", startCase(schemaItem.key));
          const hint = get(itemConfig, "hint", "");
          const description = get(itemConfig, "description", "");
          const showLabel =
            !hideCard && get(itemConfig, "componentId", "") !== "Label";
          const itemType = get(itemConfig, "type", "string");
          const forceFullWidth =
            itemType.includes("array") || itemType.includes("object");
          const columnSpan = get(itemConfig, "columnSpan", 1);

          if (itemConfig.componentId === "Hidden") {
            return null;
          }

          return (
            <ObjKey
              key={index}
              $forceFullWidth={forceFullWidth}
              $columnSpan={columnSpan}
              $active={isAdmin && hoverPath === dataPath}
              onMouseOver={(event) => handleMouseOver(dataPath, event)}
              onMouseLeave={handleMouseLeave}
              onClick={(e) => {
                e.stopPropagation();
                setEditorState({
                  activePath: dataPath.split("."),
                  anchorElement: e.currentTarget,
                  showAdvanced: false,
                });
              }}
            >
              {showLabel && (
                <Row
                  $alignitems="center"
                  $gap="5px"
                  $margin={description ? "0 0 5px 0" : "0 0 10px 0"}
                >
                  <Text
                    data={{
                      text: label,
                      fontSize: 18,
                      fontWeight: 600,
                    }}
                  />
                  {hint && <Hint hint={hint} $margin="4px 0 0 0" />}
                </Row>
              )}
              {showLabel && description && (
                <Text
                  data={{
                    text: description,
                    fontSize: 16,
                    fontWeight: 300,
                    color: "var(--dark-grey)",
                    margin: "0 0 10px 0",
                  }}
                />
              )}
              {renderField({
                schemaItem,
                schemaPath: [index],
                dataPath: [schemaItem.key],
                configPath: [schemaItem.key],
              })}
            </ObjKey>
          );
        })}
      </ObjectContainer>

      {activeSchema && (
        <Row $gap="10px" $margin="10px 0 0 0">
          <Button
            data={{
              text: "Save",
              backgroundColor: "var(--primary)",
              onClick: () => {
                setActiveDataPath(null);
                setActiveSchemaPath(null);
              },
            }}
          />
          <Button
            data={{
              text: "Delete",
              type: "basic",
              onClick: () => {
                const activeDataPathParent = activeDataPath.slice(0, -1);
                const activeDataPathIndex =
                  activeDataPath[activeDataPath.length - 1];
                const matchingData = get(
                  initialData,
                  activeDataPathParent,
                  null
                );
                const newData = matchingData.filter(
                  (_, i) => i !== activeDataPathIndex
                );
                handleChange(activeDataPathParent, newData, true);
                setActiveDataPath(null);
                setActiveSchemaPath(null);
              },
            }}
          />
        </Row>
      )}
    </MainContainer>
  );
};

export default HierarchyEditor;

const Image = styled.img`
  height: ${(p) => p.height};
  width: ${(p) => p.width};
  object-fit: ${(p) => p.objectFit || "cover"};
  border-radius: 10px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  width: 100%;
`;

const ObjKey = styled.div`
  ${(p) => (p.$columnSpan ? `grid-column: span ${p.$columnSpan};` : "")}

  @media (min-width: 1000px) {
    ${(p) => (p.$forceFullWidth ? `grid-column: span 3;` : "")}
  }

  @media (min-width: 800px) {
    ${(p) => (p.$forceFullWidth ? `grid-column: span 2;` : "")}
  }

  @media (max-width: 600px) {
    grid-column: span 1;
  }

  border-radius: 10px;

  cursor: pointer;

  outline: ${(p) => (p.$active ? "1px solid blue" : "none")};
`;

const ObjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: fit-content;
  background: white;
  gap: 15px;
  ${(p) =>
    !p.$hidecard ? `border: 1px solid var(--divider); padding: 20px;` : ""}
  width: 100%;

  ${(p) =>
    p.gridLayout &&
    `
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(${getPixels(
      p.$gridItemWidth
    )}, 1fr));
  `}

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
