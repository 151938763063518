import { Dropdown, Icon, Row, Text } from ".";

import colors from "utils/colors";
import { get } from "lodash";
import { getPixels } from "utils/utils";
import styled from "styled-components";

const PopupMenu = ({ data }) => {
  const textColor = data.textColor;

  const activeUser = get(data, "activeUser");

  const firstName = get(activeUser, "first_name");
  const lastName = get(activeUser, "last_name");
  const activeEmail = get(activeUser, "email");

  let label = `${firstName} ${lastName}`;

  if (!firstName && !lastName) {
    label = activeEmail || "Pages";
  }

  const links = get(data, "links", []);

  // NO ACTIVE USER
  if (!activeUser && links.length === 0) {
    return <></>;
  }

  const minimalSidebar = get(data, "minimalSidebar", false);

  // HAS ACTIVE USER
  let component = (
    <Row
      $alignitems="center"
      $justifycontent="space-between"
      $gap="5px"
      style={{ width: "100%", cursor: "pointer" }}
    >
      <Row
        $alignitems="center"
        $gap="10px"
        style={{ width: "100%", cursor: "pointer" }}
      >
        {activeUser && (
          <Avatar
            backgroundColor={data.backgroundColor}
            textColor={textColor}
            text={get(activeUser, "email")}
            lightMode={data.lightMode}
            size={data.size === "small" ? 28 : 34}
          />
        )}
        <div>
          <Text
            data={{
              text: label || "Options",
              color: data.lightMode ? "white" : "var(--text-color)",
              fontSize: 14,
              fontWeight: 600,
              cursor: "pointer",
              truncate: 20,
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
          />
          {activeEmail && (
            <Text
              data={{
                text: activeEmail,
                fontSize: 12,
                cursor: "pointer",
                truncate: 20,
                whiteSpace: "nowrap",
                cursor: "pointer",
                color: "var(--light-grey)",
              }}
            />
          )}
        </div>
      </Row>
    </Row>
  );

  if (data.isMobile || (!firstName && !lastName)) {
    component = (
      <Icon
        data={{
          icon: "FiMenu",
          hover: true,
          size: 30,
          color: data.lightMode ? colors.grey2 : colors.grey3,
        }}
      />
    );
  }

  if (minimalSidebar) {
    component = (
      <Icon
        data={{
          icon: "FiSettings",
          hover: true,
          size: 24,
          color: data.lightMode ? colors.grey2 : colors.grey3,
        }}
      />
    );
  }

  return (
    <Dropdown
      data={{
        optionMinWidth: data.width,
        options: links.map((l) => ({
          label: l.text,
          onClick: l.onClick,
          icon: l.icon,
        })),
        component,
      }}
    />
  );
};

const AvatarCircle = styled.div`
  border-radius: 50%;
  min-width: ${(p) => getPixels(p.size)};
  height: ${(p) => getPixels(p.size)};
  background: var(--divider);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
`;

const Avatar = ({ text, textColor, backgroundColor, size = 34 }) => {
  const initials = text && text.substring(0, 2);

  return (
    <AvatarCircle
      textColor={textColor}
      backgroundColor={backgroundColor}
      size={size}
    >
      {initials && initials.toUpperCase()}
    </AvatarCircle>
  );
};

export default PopupMenu;
