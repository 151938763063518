import { get, startCase } from "lodash";

import { Dropdown } from "components";
import colors from "utils/colors";
import moment from "moment";
import { safeArray } from "utils/utils";
import styled from "styled-components";
import { useState } from "react";

const Table = ({ data }) => {
  const [hoverIndex, setHoverIndex] = useState(null);

  const rows = safeArray(data, "rows");
  const { onRowClick } = data;

  const firstRow = get(rows, 0, {});

  let columns = get(data, "columns", []).map((c) => ({
    name: startCase(c.id),
    ...c,
  }));

  if (columns.length === 0) {
    columns = Object.keys(firstRow).map((k) => ({
      id: k,
      name: startCase(k),
      flex: 1,
    }));
  }

  const hasActions = get(firstRow, "actions", []).length > 0;

  return (
    <Columns>
      {rows.length === 0 && <Cell>No data to display</Cell>}
      {columns.map((c) => {
        const label = get(c, "label", c.name);

        return (
          <Column key={c.id} flex={c.flex}>
            <Cell fontWeight={600}>{label}</Cell>
            {rows.map((r, rowIndex) => {
              let value = get(r, c.id, "No Value") || "No Value";

              if (typeof value === "object" && value !== null) {
                value = "Click to view";
              }

              const columnType = get(c, "type");
              const componentId = get(c, "componentId");

              // IMAGE
              if (componentId === "Image") {
                value = <Thumbnail src={value} alt="table-image" />;
              }

              // BADGE
              if (columnType === "badge" || componentId === "Badge") {
                if (get(r, c.id)) {
                  value = (
                    <Badges>
                      {value.split(",").map((b, bi) => (
                        <Badge color={badgeColors[bi]}>{b}</Badge>
                      ))}
                    </Badges>
                  );
                } else {
                  value = "No tags";
                }
              }

              // This is technically a hack, but it's convenient
              if (c.id.includes("date")) {
                value = moment(value).fromNow();
              }

              return (
                <Cell
                  fontWeight={c.fontWeight || 300}
                  key={rowIndex}
                  onClick={(e) => {
                    e.stopPropagation();
                    onRowClick(rowIndex);
                  }}
                  onMouseEnter={() => setHoverIndex(rowIndex)}
                  onMouseLeave={() => setHoverIndex(null)}
                  hovering={hoverIndex === rowIndex}
                >
                  {value}
                </Cell>
              );
            })}
          </Column>
        );
      })}
      {hasActions && (
        <Column>
          <Cell fontWeight={600}>Actions</Cell>
          {rows.map((r) => (
            <Cell>
              <Dropdown
                data={{
                  icon: {
                    icon: "FiMoreHorizontal",
                    size: 25,
                    color: colors.grey,
                    margin: "0 15px 0 0",
                    hover: true,
                  },
                  options: get(r, "actions", []),
                }}
              />
            </Cell>
          ))}
        </Column>
      )}
    </Columns>
  );
};

export default Table;

const Thumbnail = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 5px;
`;

const Badges = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
`;

const Badge = styled.div`
  border-radius: 8px;
  background: ${(p) => p.color}50;
  padding: 4px 6px 4px 6px;
  width: fit-content;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 500;
`;

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${colors.inputBorder};
  border-radius: 10px;
  overflow: hidden;
  background: white;
`;

const Column = styled.div`
  width: fit-content;
  min-width: 100px;
  width: 100%;
  flex: ${(p) => p.flex || 1};
`;

const Cell = styled.div`
  font-size: 16px;
  font-weight: ${(p) => p.fontWeight || 300};
  padding: 5px 5px 5px 10px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: ${(p) => (p.flexEnd ? "flex-end" : "flex-start")};
  background-color: ${(p) =>
    p.header ? "var(--input-background)" : "transparent"};
  border-bottom: 1px solid ${colors.inputBorder};
  cursor: ${(p) => (p.onClick ? "pointer" : "default")};

  ${(p) => p.hovering && `background: ${colors.lightBackground};`}

  &:last-child {
    border-bottom: none;
  }
`;

const badgeColors = [
  "#429ef0",
  "#74c98f",
  "#f79e44",
  "#df516e",
  "#f7c744",
  "#6945e1",
];
