import colors from "utils/colors";
import { safeArray } from "utils/utils";
import { startCase } from "lodash";
import styled from "styled-components";

const List = ({ data }) => {
  const items = safeArray(data, "items");
  const { onItemClick } = data;

  return (
    <Grid>
      {items.length === 0 && <Card>No data to display</Card>}
      {items.map((item, index) => (
        <Card key={index} onClick={() => onItemClick(index)}>
          {Object.entries(item).map(([key, value]) => {
            let displayValue = value;
            if (typeof value === "object" && value !== null) {
              displayValue = "Object";
            }

            return (
              <CardContent key={key}>
                <strong>{startCase(key)}:</strong> {displayValue || "No Value"}
              </CardContent>
            );
          })}
        </Card>
      ))}
    </Grid>
  );
};

export default List;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
`;

const Card = styled.div`
  background-color: white;
  border: 1px solid ${colors.inputBorder};
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  }
`;

const CardContent = styled.div`
  font-size: 15px;
  color: var(--text-color);
`;
