const colors = {
  primary: "#014440",
  primaryDark: "#002e2b",
  ultraLightGrey: "#E6F5F3",
  border: "#e9f0ef",
  lightGrey: "#6b8f89",
  grey: "#335551",
  darkGrey: "#152624",
  border: "#e9f0ef",
  borderDark: "#d5dfde",
  lightBackground: "#f4f7f6",
  inputBorder: "#e5e4df",
  darkBeige: "#d9d8d3",
  background: "#fbf9f7",
};

export default colors;
