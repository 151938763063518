import { Icon, Menu, Row, SearchBar, Text } from ".";
import { get, startCase } from "lodash";
import { getFieldStyles, safeArray, safeLower } from "utils/utils";

import styled from "styled-components";
import { useState } from "react";

const Select = ({ data }) => {
  const { onChange } = data;

  const options = safeArray(get(data, "options", []));

  const validTypes = ["text", "number", "password", "email"];

  // HANDLE OBJECT OR STRING VALUE
  let value = get(data, "value") || get(data, "defaultValue") || "";

  const selectText =
    get(data, "selectText") ||
    data.placeholder ||
    data.noneText ||
    `Select ${safeLower(startCase(get(data, "key")))}`;

  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState("");

  const filteredOptions = options.filter(
    (o) =>
      get(o, "label", "").toLowerCase().includes(search.toLowerCase()) ||
      get(o, "value", "").toLowerCase().includes(search.toLowerCase())
  );

  const showSearch = get(data, "showSearch", false);

  const selectedOption = options.find((o) => o.value === value);

  const displayValue = selectedOption
    ? get(selectedOption, "label")
    : selectText;

  const icon = get(selectedOption, "icon");

  return (
    <>
      <Menu
        anchorElement={anchorEl}
        hide={() => setAnchorEl(null)}
        padding="0px"
        minWidth="200px"
      >
        {showSearch && (
          <SearchBar
            data={{
              value: search,
              margin: "10px 10px 0 10px",
              placeholder: "Search operators",
              onChange: (v) => setSearch(v),
              setupMode: false,
            }}
          />
        )}
        <MenuItems>
          {filteredOptions.map((o) => (
            <MenuItem
              $active={o.value === value}
              onClick={() => {
                onChange(o.value);
                setAnchorEl(null);
              }}
            >
              {o.icon && (
                <Icon
                  data={{
                    icon: o.icon,
                    size: 18,
                    color: "var(--light-grey)",
                    margin: "2px 0 0 0",
                    hover: true,
                  }}
                />
              )}
              {o.label}
            </MenuItem>
          ))}
          {filteredOptions.length === 0 && (
            <MenuItem>No results found</MenuItem>
          )}
        </MenuItems>
      </Menu>
      <Container
        {...data}
        type={validTypes.includes(data.type) ? data.type : "text"}
        width={data.width || "100%"}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Row $alignitems="center" $gap="7px">
          {icon && (
            <Icon
              data={{
                icon,
                size: 20,
                color: "var(--light-grey)",
                margin: "2px 0 0 0",
                hover: true,
              }}
            />
          )}
          <ValueText
            value={value}
            fontSize={data.fontSize}
            fontWeight={data.fontWeight}
            color={data.color}
          >
            {displayValue}
          </ValueText>
        </Row>
        <Icon
          data={{
            icon: anchorEl ? "FiChevronUp" : "FiChevronDown",
            size: 20,
            color: "var(--light-grey)",
            margin: "3px 0 0 0",
            hover: true,
          }}
        />
      </Container>
    </>
  );
};

export default Select;

const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
  padding: 8px 0 8px 0;
`;

const MenuItem = styled.div`
  padding: 10px 15px 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  color: var(--text-color);
  background: ${(p) => (p.$active ? "var(--divider)" : "transparent")};
  &:hover {
    background: var(--divider);
  }
`;

const ValueText = styled.div`
  font-size: ${(p) => p.fontSize};
  font-weight: ${(p) => p.fontWeight};
  color: ${(p) => (p.value ? p.color : "var(--light-grey)")};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(p) => getFieldStyles(p)};
  width: ${(p) => p.width};
  text-align: left;
  min-width: 50px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;
