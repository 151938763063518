import { Button, Form, Icon, Menu, Text } from "components";
import {
  Container as DndContainer,
  Draggable,
} from "@edorivai/react-smooth-dnd";
import { arrayMove, safeArray, sortFields } from "utils/utils";

import { basicFields } from "utils/formFields";
import colors from "utils/colors";
import { get } from "lodash";
import { rFormFieldsState } from "utils/recoil";
import styled from "styled-components";
import { useRecoilState } from "recoil";

const FormFields = ({ fields, setFields }) => {
  const [formFieldsState, setFormFieldsState] =
    useRecoilState(rFormFieldsState);

  const { showAdvanced, anchorElement, activeFieldIndex } = formFieldsState;

  const fieldConfig = get(fields, activeFieldIndex, {});
  const fieldType = get(fieldConfig, "type", "string");

  const formFields = basicFields({
    fieldConfig,
    fieldType,
    showAdvanced,
  });

  let finalFields = [
    {
      id: "key",
      label: "Key",
      componentId: "Input",
      value: get(fieldConfig, "key", ""),
    },
    {
      id: "type",
      label: "Type",
      componentId: "Select",
      value: get(fieldConfig, "type", "string"),
      options: [
        { label: "String", value: "string" },
        { label: "Integer", value: "integer" },
        { label: "Boolean", value: "boolean" },
        // { label: "Number", value: "number" },
        // { label: "Datetime", value: "datetime" },
        // { label: "File", value: "file" },
        // { label: "Password", value: "password" },
      ],
    },
    ...formFields,
    {
      id: "columnSpan",
      label: "Column Span",
      componentId: "Select",
      value: get(fieldConfig, "columnSpan", 1),
      hint: "The number of columns to span in the grid",
      options: [
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
      ],
    },
  ];

  const fieldSorting = ["label"];

  finalFields = sortFields({
    schema: finalFields,
    sortingArray: fieldSorting,
    objectKey: "id",
  });

  const addField = () => {
    setFields([...fields, { key: "new_field", label: "New Field" }]);
  };

  const onFieldChange = (k, v) => {
    setFields(
      fields.map((f, i) => {
        if (i === activeFieldIndex) {
          return { ...f, [k]: v };
        }
        return f;
      })
    );
  };

  const onDrop = ({ addedIndex, removedIndex }) => {
    const movedFields = arrayMove(fields, removedIndex, addedIndex);
    setFields(movedFields);
  };

  return (
    <>
      <Menu
        background={"white"}
        anchorElement={anchorElement}
        hide={() => {
          setFormFieldsState({
            ...formFieldsState,
            anchorElement: null,
            activeFieldIndex: null,
          });
        }}
        width={"300px"}
      >
        <Form onChange={onFieldChange} fields={finalFields} />
        <Text
          data={{
            text: showAdvanced
              ? "Hide advanced settings"
              : "View more settings",
            fontWeight: 600,
            fontSize: 15,
            cursor: "pointer",
            color: colors.primary,
            margin: "15px 0 0 0",
            onClick: () =>
              setFormFieldsState({
                ...formFieldsState,
                showAdvanced: !showAdvanced,
              }),
          }}
        />
      </Menu>

      {fields.length > 0 && (
        <DndContainer
          onDrop={onDrop}
          dragHandleSelector=".drag-item"
          lockAxis="y"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            margin: "0 0 10px 0",
          }}
        >
          {safeArray(fields).map((f, i) => (
            <Draggable key={i}>
              <Field
                field={f}
                onDelete={() => {
                  setFields(fields.filter((_, fieldIndex) => i !== fieldIndex));
                }}
                onClick={(e) =>
                  setFormFieldsState({
                    ...formFieldsState,
                    activeFieldIndex: i,
                    anchorElement: e.currentTarget,
                  })
                }
              />
            </Draggable>
          ))}
        </DndContainer>
      )}
      <Button
        data={{
          text: "Add Field",
          type: "basic",
          size: "small",
          icon: "FiPlus",
          onClick: addField,
        }}
      />
    </>
  );
};

export default FormFields;

const Field = ({ field, onClick, onDelete }) => {
  return (
    <FieldContainer onClick={onClick} className="drag-item">
      {field.label}
      <Icon
        data={{
          icon: "FiTrash",
          size: 16,
          color: "#727272",
          hover: true,
          onClick: (e) => {
            e.stopPropagation();
            onDelete();
          },
        }}
      />
    </FieldContainer>
  );
};

const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border: 1px solid ${colors.inputBorder};
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.lightBackground};
  }
`;
