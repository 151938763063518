import { get } from "lodash";
import { getComponentIdOptions } from "./utils";

export const basicFields = ({
  fieldConfig,
  fieldType,
  isObjectArray = false,
  showAdvanced = false,
}) => {
  return [
    {
      id: "componentId",
      label: "Display Component",
      componentId: "Select",
      value: get(fieldConfig, "componentId", "Input"),
      defaultValue: "Input",
      hideEmptyItem: true,
      options: getComponentIdOptions(fieldType, isObjectArray),
      displayCondition: () =>
        isObjectArray ||
        fieldType.includes("string") ||
        fieldType === "integer",
    },
    {
      id: "label",
      label: "Label",
      componentId: "Input",
      value: get(fieldConfig, "label"),
      hint: "Adjust the visual label",
      displayCondition: () => !get(fieldConfig, "hideCard"),
    },
    {
      id: "options",
      label: "Options",
      componentId: "KeyValuePairs",
      hint: "Options for the select dropdown and the Enum option",
      useLabelAsKey: true,
      value: get(fieldConfig, "options", []),
      displayCondition: () => get(fieldConfig, "componentId") === "Select",
    },
    {
      id: "disabled",
      label: "Disabled",
      componentId: "Switch",
      value: get(fieldConfig, "disabled", false),
      displayCondition: () =>
        ["Input", "TextArea", "MarkdownEditor", "Select"].includes(
          get(fieldConfig, "componentId")
        ),
    },
    {
      id: "description",
      label: "Description",
      componentId: "TextArea",
      value: get(fieldConfig, "description"),
      displayCondition: () => showAdvanced && !get(fieldConfig, "hideCard"),
    },
    {
      id: "placeholder",
      label: "Placeholder",
      componentId: "Input",
      value: get(fieldConfig, "placeholder"),
      hint: "The default text to appear in the input when blank",
      displayCondition: () =>
        showAdvanced &&
        ["Input", "TextArea"].includes(
          get(fieldConfig, "componentId", "Input")
        ),
    },
    {
      id: "hint",
      label: "Hint",
      componentId: "Input",
      value: get(fieldConfig, "hint"),
      hint: "Additional information to help the user understand the field",
      displayCondition: () => showAdvanced && !get(fieldConfig, "hideCard"),
    },
    {
      id: "height",
      label: "Height",
      componentId: "Input",
      value: get(fieldConfig, "height"),
      hint: "The height of the component in pixels",
      displayCondition: () =>
        showAdvanced &&
        ["Image", "TextArea", "MarkdownEditor"].includes(
          get(fieldConfig, "componentId")
        ),
    },
    {
      id: "width",
      label: "Width",
      componentId: "Input",
      value: get(fieldConfig, "width"),
      hint: "The width of the component in pixels",
      displayCondition: () =>
        ["Image"].includes(get(fieldConfig, "componentId")),
    },
    {
      id: "objectFit",
      label: "Image Fit",
      componentId: "Select",
      options: [
        { label: "Cover", value: "cover" },
        { label: "Contain", value: "contain" },
        { label: "Fill", value: "fill" },
      ],
      value: get(fieldConfig, "objectFit", "cover"),
      displayCondition: () =>
        ["Image"].includes(get(fieldConfig, "componentId")),
    },
  ].filter(
    (f) =>
      !f.displayCondition ||
      (f.displayCondition && f.displayCondition(fieldConfig))
  );
};
