import { Button, Row, Text } from "components";

import AdminWrapper from "components/AdminWrapper";
import colors from "utils/colors";
import { get } from "lodash";
import { rAccount } from "utils/recoil";
import styled from "styled-components";
import { useEffect } from "react";
import useMixpanel from "utils/useMixpanel";
import { useRecoilValue } from "recoil";

const Billing = () => {
  // Track mixpanel
  const { track } = useMixpanel();
  useEffect(() => {
    track("Billing");
  }, []);

  const account = useRecoilValue(rAccount);

  const sessionCount = get(account, "session_count", 0);
  const sessionLimit = get(account, "credit_limit", 100);
  const hasPayments = get(account, "has_payments", false);

  return (
    <AdminWrapper title="Billing">
      <Container>
        <Row $gap="10px">
          <Text
            data={{
              text: `Your Usage:`,
              fontSize: 30,
              fontWeight: 600,
            }}
          />
          <Text
            data={{
              text: `${sessionCount} / ${sessionLimit} ${
                hasPayments ? "Sessions" : "Sessions (Free Trial)"
              }`,
              fontSize: 30,
              fontWeight: 400,
            }}
          />
        </Row>

        {!hasPayments && (
          <Text
            data={{
              text: "You have 50 free session credits included with your free account.",
              fontSize: 20,
              margin: "30px 0 15px 0",
              color: "var(--dark-grey)",
            }}
          />
        )}

        <Text
          data={{
            text: "Looking for more? Buy more credits in increments of 500 for $10.",
            fontSize: 20,
            margin: "30px 0 30px 0",
          }}
        />

        <Button
          data={{
            text: "Buy 500 Credits - $10.00",
            size: "large",
            onClick: () =>
              (window.location.href =
                "https://buy.stripe.com/bIYcNGesJ05m5yM29G"),
          }}
        />
      </Container>
    </AdminWrapper>
  );
};

export default Billing;

const Container = styled.div`
  background: white;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid ${colors.inputBorder};
`;
