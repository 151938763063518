import { Text } from "components";
import colors from "utils/colors";
import curatorLogoWhite from "images/curator-white.png";
import mixpanel from "mixpanel-browser";
import styled from "styled-components";

const Container = styled.div`
  background: ${colors.primary};
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 7px;
`;

const Logo = styled.img`
  height: 18px;
`;

const MadeWith = () => {
  return (
    <Container
      onClick={() => {
        window.open("https://curatorapp.ai?r=madewithbadge");
        mixpanel.track("Made With Curator Badge");
      }}
    >
      <Text
        data={{
          text: "Made with",
          color: "white",
          fontStyle: "headingXs",
          cursor: "pointer",
        }}
      />
      <Logo src={curatorLogoWhite} />
    </Container>
  );
};

export default MadeWith;
