import { Button, Row, Spinner, Table, Tabs, Text } from "components";
import { get, isEmpty } from "lodash";
import { rAccount, rUser } from "utils/recoil";
import { useEffect, useState } from "react";

import MadeWith from "components/MadeWith";
import { apiRequest } from "utils/apiRequests";
import { getPixels } from "utils/utils";
import styled from "styled-components";
import useMixpanel from "utils/useMixpanel";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

const Dashboard = () => {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sessionsFetched, setSessionsFetched] = useState(false);

  const navigate = useNavigate();

  const account = useRecoilValue(rAccount);

  const logo = get(account, "logo", null);

  const user = useRecoilValue(rUser);
  const [flagType, setFlagType] = useState("flagged");

  // Track mixpanel
  const { track } = useMixpanel();
  useEffect(() => {
    track("Sessions Dashboard");
  }, []);

  const fetchSessions = (flagType) => {
    setLoading(true);
    apiRequest.get(`/sessions/?flag_type=${flagType}`).then((res) => {
      setSessions(get(res, "data", []));
      setLoading(false);
      setSessionsFetched(true);
    });
  };

  // Get users
  useEffect(() => {
    if (!isEmpty(account) && !sessionsFetched) {
      fetchSessions(flagType);
    }
  }, [account]);

  const updateFlagType = (flagType) => {
    setFlagType(flagType);
    fetchSessions(flagType);
  };

  return (
    <Container>
      {!user && <MadeWith />}
      <Content $maxWidth={"1000px"}>
        {logo && <Logo src={logo} />}
        <Row
          $alignitems="center"
          $justifycontent="space-between"
          $margin="0 0 30px 0"
        >
          <Text
            data={{
              text: "Pending Confirmations",
              fontWeight: 700,
              fontSize: 30,
              margin: "0 0 4px 0",
            }}
          />

          <Tabs
            data={{
              margin: "0 0 20px 0",
              tabs: [
                {
                  label: "All",
                  active: flagType === "all",
                  onClick: () => updateFlagType("all"),
                },
                {
                  label: "Flagged",
                  active: flagType === "flagged",
                  onClick: () => updateFlagType("flagged"),
                },
                {
                  label: "Unflagged",
                  active: flagType === "unflagged",
                  onClick: () => updateFlagType("unflagged"),
                },
              ],
            }}
          />
        </Row>

        {loading ? (
          <Spinner />
        ) : (
          <>
            {sessions.length === 0 && (
              <Text
                data={{
                  text: "You have no pending confirmations at this time.",
                  fontSize: 20,
                }}
              />
            )}

            {sessions.length > 0 && (
              <Table
                data={{
                  rows: sessions.map((s) => ({
                    ...s,
                    // actions: [
                    //   {
                    //     label: "Delete",
                    //     onClick: () => console.log("DELETE SESSION", s),
                    //   },
                    // ],
                  })),
                  columns: [
                    {
                      id: "title",
                      fontWeight: 400,
                      flex: 2,
                    },
                    {
                      id: "created_date",
                      flex: 1,
                    },
                    {
                      id: "tags",
                      type: "badge",
                      flex: 1,
                    },
                  ],
                  onRowClick: (i) =>
                    navigate(`/session/${get(sessions, [i, "uuid"])}`),
                }}
              />
            )}
          </>
        )}
      </Content>
    </Container>
  );
};

export default Dashboard;

const Container = styled.div`
  padding: 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
`;

const Content = styled.div`
  width: 100%;
  max-width: ${(p) => getPixels(p.$maxWidth || 800)};
  margin: 0 auto;
`;

const Logo = styled.img`
  height: 40px;
  width: 150px;
  object-fit: contain;
  margin-bottom: 30px;
  object-position: left;
`;
