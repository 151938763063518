import { Icon } from "components";
import { getPixels } from "utils/utils";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: ${(p) => p.$margin};
`;

const Crumb = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.div`
  font-size: ${(p) => getPixels(p.fontSize)};
  font-weight: ${(p) => p.fontWeight || 500};
  cursor: pointer;
`;

const Breadcrumb = ({
  items,
  margin = "0px",
  activeItem = null,
  fontSize = 18,
  fontWeight = 500,
}) => {
  return (
    <Container $margin={margin}>
      {items.map((item, i) => {
        // If active item is passed in, use that, otherwise the last item is always the active one
        const active =
          activeItem !== null ? i === activeItem : i === items.length - 1;

        return (
          <Crumb key={i}>
            <Text
              fontSize={fontSize}
              fontWeight={fontWeight}
              onClick={item.onClick}
              color={active ? "var(--text-color)" : "var(--light-grey)"}
            >
              {item.text}
            </Text>
            {i < items.length - 1 && (
              <Icon
                data={{
                  icon: "FiChevronRight",
                  color: "var(--light-grey)",
                  size: 20,
                  margin: "2px 5px 0 5px",
                }}
              />
            )}
          </Crumb>
        );
      })}
    </Container>
  );
};

export default Breadcrumb;
