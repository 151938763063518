import {
  cloneDeep,
  get,
  isArray,
  isInteger,
  isNaN,
  isNil,
  isString,
  startsWith,
} from "lodash";

export const resizeImage = ({
  url,
  height,
  quality = null,
  forceJpg = false,
  extraMods = null,
}) => {
  if (!url) {
    return null;
  }

  url = url.toString();

  if (!url.includes("cloudinary")) {
    return url;
  }

  // Don't resize PDF
  if (url.includes(".pdf")) {
    return url;
  }

  const isResized = url.includes(`/h_`);

  let finalUrl = url;

  let mods = `h_${height}/`;

  if (extraMods) {
    mods = `${extraMods},${mods}/`;
  }

  if (quality) {
    mods = `${mods}q_${quality}/`;
  }

  if (forceJpg) {
    mods = `${mods}f_jpg/`;
  }

  if (isResized) {
    // ALREADY RESIZED
    const splitUrl = url.split("upload/");
    const existingMod = splitUrl[1].split("/")[0];
    finalUrl = url.replace(existingMod, mods);
  } else {
    // NOT RESIZED
    const splitUrl = url.split("upload/");
    const newUrl = `${splitUrl[0]}upload/${mods}${splitUrl[1]}`;
    finalUrl = newUrl;
  }

  return finalUrl;
};

export const sortFields = ({ schema, sortingArray, objectKey = null }) => {
  const sortedSchemaKeys = schema.sort((a, b) => {
    const aIndex = sortingArray.indexOf(get(a, objectKey));
    const bIndex = sortingArray.indexOf(get(b, objectKey));

    // Handle missing values by placing them at the end
    return (
      (aIndex === -1 ? Infinity : aIndex) - (bIndex === -1 ? Infinity : bIndex)
    );
  });

  return sortedSchemaKeys;
};

// export const sortFields = ({ schema, sortingArray, objectKey = null }) => {
//   const sortedSchemaKeys = sortingArray.length
//     ? schema.sort((a, b) => {
//         if (objectKey) {
//           return (
//             sortingArray.indexOf(get(a, objectKey)) -
//             sortingArray.indexOf(get(b, objectKey))
//           );
//         }
//         return sortingArray.indexOf(a) - sortingArray.indexOf(b);
//       })
//     : schema;

//   return sortedSchemaKeys;
// };

export const getUrlParameter = (name, location) => {
  if (!name) {
    return null;
  }

  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  const results = regex.exec(location.search);
  let value =
    results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));

  if (value === "true") {
    value = true;
  } else if (value === "false") {
    value = false;
  } else if (isInteger(value)) {
    return parseInt(value);
  }

  return value;
};

export const publicRoutes = ["/", "/login", "/signup", "/browse", "/session"];

export const safeString = (str) => {
  if (str === null || str === undefined) {
    return "";
  }

  return str.toString();
};

export const safeToFixed = (value, decimals) => {
  if (!value) {
    const num = 0;
    return num.toFixed(decimals);
  }

  try {
    return parseFloat(value).toFixed(decimals);
  } catch (error) {
    const num = 0;
    return num.toFixed(decimals);
  }
};

export const safeArray = (obj, key = null) => {
  let potentialArray = obj;

  if (key) {
    potentialArray = get(obj, key, []);
  }

  if (isArray(potentialArray)) {
    return potentialArray;
  }

  return [];
};

export const sortItems = (items, listKey, sortingOrder, sortingKey) => {
  let newItems = [];
  sortingOrder.forEach((orderItem) => {
    const sortKeyValue = get(orderItem, sortingKey);
    const match = items.find((i) => get(i, listKey) === sortKeyValue);
    if (match) {
      newItems.push({ ...match, ...orderItem });
    }
  });

  const orderedIds = newItems.map((i) => get(i, listKey));

  items
    .filter((i) => !orderedIds.includes(get(i, listKey)))
    .forEach((item) => {
      newItems.push(item);
    });
  return newItems;
};

export const parseNumber = (value) => {
  if (value) {
    // Remove commas, spaces, and any currency symbols
    const cleanedValue = value.toString().replace(/[,€£$¥₹%\s]/g, "");

    // Check if cleaned value contains a float
    if (/^-?\d+\.\d+$/.test(cleanedValue)) {
      return parseFloat(cleanedValue);
    }

    // Check if cleaned value contains an integer
    if (/^-?\d+$/.test(cleanedValue)) {
      return parseInt(cleanedValue, 10);
    }

    // If value contains numbers mixed with letters or is invalid, return 0
    return 0;
  }
  return 0;
};

export const safeLower = (str) => {
  if (typeof str === "string") {
    return str.toLowerCase();
  }

  if (isNil(str)) {
    return "";
  }

  return str.toString();
};

export function safeParseFloatOrInt(str) {
  const parsedFloat = parseFloat(str);
  const parsedInt = parseInt(str, 10);

  // Check if the parsedFloat is a valid number (not NaN) and the same as the parsedInt
  if (!isNaN(parsedFloat) && parsedFloat === parsedInt) {
    return parsedInt; // Return the parsed integer if it's a valid integer
  } else if (!isNaN(parsedFloat)) {
    return parsedFloat; // Return the parsed float if it's a valid float
  } else {
    return 0; // Return 0 if neither a valid float nor integer
  }
}

export const getHighest = (source, field = "id") => {
  let highestId = 0;

  if (!source) {
    return highestId;
  }

  safeArray(source).forEach((f) => {
    const match = get(f, field) && parseInt(get(f, field));
    if (match && match > highestId) {
      highestId = match;
    }
  });
  return highestId;
};

export const arrayMove = (arrayItems, removedIndex, addedIndex) => {
  const arrCopy = cloneDeep(arrayItems);
  const removedItem = arrCopy.splice(removedIndex, 1);
  arrCopy.splice(addedIndex, 0, removedItem[0]);

  return arrCopy;
};

export const addSlash = (route) => {
  if (!route) {
    return null;
  }
  if (startsWith(route, "/") || route.includes("http")) {
    return route;
  }
  return `/${route}`;
};

export const getPixels = (number) => {
  if (number === 0 || number === "0") {
    return "0px";
  }
  // VIEWHEIGHT STRING
  if (isString(number) && number.endsWith("vh")) {
    return number;
  }
  // PERCENT STRING
  if (isString(number) && number.endsWith("%")) {
    return number;
  }
  // PIXEL STRING
  if (isString(number) && number.includes("px")) {
    return number;
  }
  // INTEGER
  if (parseInt(number)) {
    return `${parseInt(number)}px`;
  }
  return number;
};

export const getValueType = (value) => {
  let valueType = typeof value;

  if (Array.isArray(value)) {
    valueType = "array";
  } else if (value === "") {
    valueType = "empty string";
  } else if (value === null) {
    valueType = "null";
  }
  return valueType;
};

export const parseBoolean = (value) => {
  if (typeof value === "boolean") return value;

  if (typeof value === "string") {
    const trimmedValue = value.trim().toLowerCase();

    switch (trimmedValue) {
      case "true":
        return true;
      case "false":
        return false;
      default:
        return false;
    }
  }

  return false;
};

// This function applies consistent styles to inputs across form fields
export const getFieldStyles = (p) => {
  return `
    padding: ${p.padding || "var(--input-padding)"};
    border: ${p.border || `1px solid var(--divider)`};
    border-width: ${p.borderWidth || "1px"};
    border-radius: ${getPixels(
      p.$borderradius || "var(--input-border-radius)"
    )};
    height: ${p.inputHeight || "40px"};
    font-weight: ${p.fontWeight || 400};
    background: ${p.background || "var(--input-background)"};
    color: ${p.color || "var(--text-color)"};
    font-size: ${p.fontSize ? getPixels(p.fontSize) : "14px"};
    width: ${p.width || "100%"};
  `;
};

export const getUniqueValues = (array, key, splitValues = false) => {
  if (!isArray(array)) {
    return [];
  }

  if (splitValues) {
    // Split each array item (a comma-separated string) into a new flat list
    const finalArray = array
      .reduce((result, item) => {
        const beforeSplit = safeString(get(item, key, "")).toString();
        const splitItem = beforeSplit.split(",");
        return result.concat(splitItem);
      }, [])
      .map((v) => v.trim());

    const uniqueVals = finalArray.reduce((result, item) => {
      if (result.indexOf(item) === -1) {
        result.push(item);
      }
      return result;
    }, []);
    return uniqueVals;
  }

  let uniqueValues = array.reduce((result, item) => {
    if (result.indexOf(item[key]) === -1) {
      result.push(get(item, key));
    }
    return result;
  }, []);

  return uniqueValues;
};

export const primTypes = [
  { label: "Input", value: "Input" },
  {
    label: "Editable Label",
    value: "Label",
  },
  { label: "TextArea", value: "TextArea" },
  { label: "Dropdown", value: "Select" },
  { label: "Switch", value: "Switch" },
  {
    label: "Markdown Editor",
    value: "MarkdownEditor",
  },
  {
    label: "Hidden",
    value: "Hidden",
  },
  {
    label: "View Only",
    value: "ViewOnly",
  },
  {
    label: "Image",
    value: "Image",
  },
];

export const numberTypes = [
  { label: "Input", value: "Input" },
  {
    label: "Editable Label",
    value: "Label",
  },
  { label: "Dropdown", value: "Select" },
  {
    label: "Hidden",
    value: "Hidden",
  },
  {
    label: "View Only",
    value: "ViewOnly",
  },
];

export const getComponentIdOptions = (type, isObjectArray) => {
  if (isObjectArray) {
    return [
      { label: "List", value: "List" },
      { label: "Table", value: "Table" },
      { label: "Form", value: "Form" },
    ];
  }

  if (type === "array") {
    // Array of primitives
    return primTypes;
  }
  if (type === "string") {
    return primTypes;
  }
  // Integer
  if (type === "integer") {
    return numberTypes;
  }

  return "Input";
};

export const isValidJson = (inputJson) => {
  try {
    JSON.parse(inputJson);
    return true;
  } catch (error) {
    return false;
  }
};

export const getValidJson = (inputJson) => {
  try {
    return JSON.parse(inputJson);
  } catch (error) {
    return {};
  }
};

export const getType = (value) => {
  if (value === null) {
    return "null";
  } else if (typeof value === "string") {
    // if (isValidDate(value)) {
    //   return "date";
    // } else {
    // }
    return "string";
  } else if (typeof value === "number" && Number.isInteger(value)) {
    return "number";
    // return "integer";
  } else if (typeof value === "number") {
    return "number";
  } else if (typeof value === "boolean") {
    return "boolean";
  } else if (Array.isArray(value)) {
    return "array";
  } else if (typeof value === "object") {
    return "object";
  }
};

export const convertJsonToSchema = (json) => {
  const uniqueValues = {};

  const convertItem = (key, value, path = "") => {
    const fullPath = path ? `${path}.${key}` : key;
    const item = { key };

    const valueType = getType(value);

    if (valueType === "object") {
      item.type = "object";
      const allKeys = new Set();
      Object.keys(value).forEach((k) => allKeys.add(k));
      item.keys = Array.from(allKeys).map((k) =>
        convertItem(k, value[k], fullPath)
      );
    } else if (valueType === "array") {
      item.type = "array";
      const arrayElType = getType(value[0]);

      if (arrayElType === "object") {
        item.array_type = "object";
        const allKeys = new Set();
        value.forEach((val) => {
          Object.keys(val).forEach((k) => allKeys.add(k));
        });
        item.keys = Array.from(allKeys).map((k) =>
          convertItem(k, value[0][k], fullPath)
        );
        // Collect unique values from all objects in the array
        value.forEach((val) => {
          item.keys.forEach((keyItem) => {
            const nestedFullPath = `${fullPath}.${keyItem.key}`;
            const uniqueVals = get(uniqueValues, nestedFullPath, []);
            if (!uniqueVals.includes(val[keyItem.key])) {
              uniqueValues[nestedFullPath] = [...uniqueVals, val[keyItem.key]];
            }
          });
        });
      } else {
        item.array_type = arrayElType;
        // Collect unique values from the array
        value.forEach((val) => {
          const uniqueVals = get(uniqueValues, fullPath, []);
          if (!uniqueVals.includes(val)) {
            uniqueValues[fullPath] = [...uniqueVals, val];
          }
        });
      }
    } else {
      item.type = value === null ? "string" : typeof value; // Default to 'string' if value is null

      if (item.type === "string" || item.type === "number") {
        const uniqueVals = get(uniqueValues, fullPath, []);

        let newVals = [...uniqueVals];

        if (!uniqueVals.includes(value)) {
          newVals = [...uniqueVals, value];
        }

        uniqueValues[fullPath] = newVals;
      }
    }

    return item;
  };

  const schema = convertItem("root", json);

  const addEnums = (item, path = "") => {
    const fullPath = path ? `${path}.${item.key}` : item.key;
    if (item.keys) {
      item.keys.forEach((child) => addEnums(child, fullPath));
    } else if (uniqueValues[fullPath]) {
      item.enum = Array.from(uniqueValues[fullPath]);
    }
  };

  addEnums(schema);

  return schema;
};

export const convertToSimplifiedData = (schema) => {
  const isAdmin = true;
  const simplified = {};

  schema.forEach((item) => {
    const key = get(item, "key", "");

    const defaultString = ""; // isAdmin ? "example" : "";
    const defaultNumber = isAdmin ? 123 : "";
    const defaultBoolean = isAdmin ? true : "";
    const defaultStringArray = isAdmin
      ? ["example 1", "example 2", "example 3"]
      : [];
    const defaultNumberArray = isAdmin ? [123, 456, 789] : [];

    const itemType = get(item, "type", "string");

    if (itemType === "string") {
      simplified[key] = defaultString;
    } else if (itemType === "number") {
      simplified[key] = defaultNumber;
    } else if (itemType === "boolean") {
      simplified[key] = defaultBoolean;
    } else if (itemType === "array") {
      const arrayType = get(item, "array_type", "string");
      if (arrayType === "string") {
        simplified[key] = defaultStringArray;
      } else if (arrayType === "number") {
        simplified[key] = defaultNumberArray;
      } else if (arrayType === "object") {
        simplified[key] = [convertToSimplifiedData(get(item, "keys", []))];
      }
    } else if (itemType === "object") {
      simplified[key] = convertToSimplifiedData(get(item, "keys", []));
    }
  });

  return simplified;
};
