const createBlob = (color) => [
  `<svg viewBox="0 0 220 200" xmlns="http://www.w3.org/2000/svg">
     <path fill="${color}" d="M15.9,-15.9C25.9,-5.9,43,-3,51.1,8.1C59.2,19.1,58.2,38.2,48.2,47C38.2,55.8,19.1,54.3,1.7,52.6C-15.7,50.9,-31.4,49,-41.5,40.2C-51.5,31.4,-56,15.7,-56.6,-0.6C-57.2,-16.8,-53.8,-33.7,-43.7,-43.7C-33.7,-53.7,-16.8,-57,-6.9,-50C3,-43.1,5.9,-26,15.9,-15.9Z" transform="translate(100 100)" />
   </svg>`,
  `<svg viewBox="0 0 300 200" xmlns="http://www.w3.org/2000/svg">
  <path fill="${color}" d="M17.5,-24.9C25.6,-9.5,36.8,-4.8,41.7,4.9C46.6,14.5,45,29,37,40C29,51.1,14.5,58.8,-3.9,62.7C-22.3,66.6,-44.6,66.7,-48.1,55.7C-51.6,44.6,-36.4,22.3,-28.9,7.5C-21.4,-7.3,-21.6,-14.5,-18.1,-29.9C-14.5,-45.3,-7.3,-68.7,-1.3,-67.4C4.8,-66.2,9.5,-40.2,17.5,-24.9Z" transform="translate(100 100)" />
</svg>`,
  `<svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
  <path fill="${color}" d="M37,-33.8C49,-24.9,60.8,-12.4,56.8,-4C52.7,4.4,32.9,8.7,20.8,17.2C8.7,25.7,4.4,38.3,-3.6,41.9C-11.6,45.5,-23.2,40.2,-27.5,31.7C-31.9,23.2,-29,11.6,-26.5,2.5C-23.9,-6.5,-21.8,-13.1,-17.4,-22.1C-13.1,-31,-6.5,-42.5,2.9,-45.4C12.4,-48.3,24.9,-42.8,37,-33.8Z" transform="translate(100 100)" />
</svg>`,
  `<svg viewBox="0 0 250 200" xmlns="http://www.w3.org/2000/svg">
  <path fill="${color}" d="M26,-36.8C35.1,-29.1,45.1,-23.5,54.3,-13.3C63.6,-3.2,72.2,11.6,65.7,18.1C59.1,24.7,37.4,23.2,23.9,24.5C10.5,25.9,5.2,30.1,-1.5,32.2C-8.2,34.2,-16.4,34.1,-21.6,30C-26.8,26,-29,18.1,-30,10.8C-30.9,3.5,-30.6,-3.2,-27.6,-7.9C-24.7,-12.7,-19.1,-15.6,-14,-24.6C-9,-33.6,-4.5,-48.7,2,-51.4C8.4,-54.1,16.8,-44.4,26,-36.8Z" transform="translate(100 100)" />
</svg>`,
  `<svg viewBox="0 0 300 200" xmlns="http://www.w3.org/2000/svg">
  <path fill="${color}" d="M26.4,-29.6C40.8,-19.4,63.6,-17.3,69.3,-8.7C75.1,0,63.9,15.2,54.9,32.7C46,50.3,39.4,70.1,26,77.7C12.6,85.2,-7.5,80.5,-23,71.3C-38.5,62.1,-49.3,48.4,-50.8,34.7C-52.3,21,-44.3,7.3,-39.2,-4.3C-34.1,-16,-31.8,-25.6,-25.7,-37.2C-19.6,-48.8,-9.8,-62.5,-1.9,-60.3C6,-58,12.1,-39.8,26.4,-29.6Z" transform="translate(100 100)" />
</svg>`,
  `<svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
  <path fill="${color}" d="M38.6,-42.5C52.9,-33.9,69.4,-24.5,76.9,-9.5C84.5,5.6,83.1,26.4,73.9,43.1C64.8,59.9,47.9,72.6,29.6,77.7C11.3,82.8,-8.5,80.3,-26.9,73.7C-45.3,67.1,-62.3,56.5,-67.6,41.7C-72.9,27,-66.4,8.2,-58.5,-5.8C-50.6,-19.8,-41.3,-29,-31.3,-38.2C-21.3,-47.5,-10.7,-56.9,0.7,-57.7C12.1,-58.6,24.2,-51,38.6,-42.5Z" transform="translate(100 100)" />
</svg>`,
];

export default createBlob;
