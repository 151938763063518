import { Icon } from "components";
import colors from "utils/colors";
import { get } from "lodash";
import styled from "styled-components";

const NavTabs = styled.div`
  display: flex;
  margin: ${(p) => p.margin};
  background: #f0f0f4;
  border-radius: 6px;
  padding: 3px;
  gap: 3px;
  width: ${(p) => p.width || "auto"};
`;

const NavTab = styled.div`
  border-radius: 6px;
  background: ${(p) => (p.active ? "white" : "transparent")};
  color: ${colors.default};
  font-size: 14px;
  font-weight: 600;
  padding: ${(p) => p.padding || "5px"};
  flex: 1;
  text-align: center;
  cursor: pointer;
  ${(p) =>
    p.active &&
    `box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15);`}
`;

const SelectToggle = ({ data }) => {
  const { margin, tabs, onChange, width } = data;

  const value = get(data, "value", get(data, "defaultValue", null));

  return (
    <NavTabs margin={margin} width={width}>
      {tabs.map((tab, index) => {
        return (
          <NavTab
            padding={tab.icon && "5px 5px 2px 5px"}
            key={index}
            active={tab.active === undefined ? value === tab.value : tab.active}
            onClick={() => onChange(tab.value)}
          >
            {tab.icon ? (
              <Icon
                data={{
                  icon: tab.icon,
                  size: 24,
                  color: colors.grey4,
                  hover: true,
                  rotate: tab.rotate,
                }}
              />
            ) : (
              tab.label
            )}
          </NavTab>
        );
      })}
    </NavTabs>
  );
};

export default SelectToggle;
