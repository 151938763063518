// import logo from "images/curator-logo.png";
import { Column, Spinner } from "components";

import styled from "styled-components";

const Loader = () => {
  return (
    <Container>
      <Column $gap="30px" $alignitems="center" $justifycontent="center">
        {/* <Logo src={logo} /> */}
        <Spinner color="var(--text-color)" size={40} />
      </Column>
    </Container>
  );
};

export default Loader;

const Logo = styled.img`
  height: 40px;
`;

const Container = styled.div`
  background: var(--background);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
