import { Icon, Text } from "components";
import { startCase, upperCase } from "lodash";

import colors from "utils/colors";
import createBlob from "./blobs";
import styled from "styled-components";

const Resource = ({ label, type, link, description, color, index }) => {
  const blobColor = `rgba(0, 0, 0, 0.2)`;
  const backgroundColor = `${color}`;
  const accentColor = `${color}99`;

  const icon = type === "article" ? "FiBook" : "FiVideo";

  return (
    <ResourceContainer
      onClick={() => window.open(link)}
      color={backgroundColor}
      accentColor={accentColor}
    >
      <BlobContainer
        dangerouslySetInnerHTML={{
          __html: createBlob(blobColor)[index % createBlob(blobColor).length],
        }}
      />

      <Name>{label}</Name>
      <Description>{description}</Description>

      <TypeContainer>
        <Icon
          data={{
            icon,
            size: type === "article" ? 16 : 18,
            color: "white",
            hover: true,
            margin: "1px 0 0 0",
          }}
        />
        <Text
          data={{
            text: startCase(type),
            fontSize: 14,
            fontWeight: 700,
            color: "white",
          }}
        />
      </TypeContainer>
    </ResourceContainer>
  );
};

export default Resource;

const ResourceContainer = styled.div`
  position: relative;
  padding: 15px;
  border-radius: 10px;
  background: white;
  background: ${({ color }) => color};
  //   border: 1px solid ${colors.inputBorder};
  display: flex;
  flex-direction: column;
  cursor: pointer;
  min-height: fit-content;
  overflow: hidden;
  &:hover {
    background: ${({ accentColor }) => accentColor};
  }
`;

const BlobContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400%;
  height: 400%;
  transform: translate(-45%, -50%);
  svg {
    width: 100%;
    height: 100%;
    fill: ${({ color }) => color};
  }
`;

const TypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  z-index: 1;
  padding: 7px;
  border-radius: 5px;
  width: fit-content;
  margin-top: 10px;
  background: rgba(0, 0, 0, 0.15);
`;

const Name = styled.div`
  color: white;
  font-size: 28px;
  font-weight: 800;
  z-index: 1;
  line-height: 1;
  margin-bottom: 5px;
`;

const Description = styled.div`
  color: white;
  font-size: 16px;
  font-weight: 400;
  z-index: 1;
`;
