import {
  Button,
  FormInputWrapper,
  Icon,
  Input,
  Modal,
  Row,
  Text,
} from "components";
import { apiRequest, handleError } from "utils/apiRequests";
import { errorNotification, successNotification } from "utils/notification";
import { get, isEmpty } from "lodash";
import { useEffect, useState } from "react";

import Cookies from "js-cookie";
import Form from "components/Form";
import colors from "utils/colors";
import logo from "images/curator-green.png";
import { rUser } from "utils/recoil";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

const features = [
  "Build effortless multi-stage AI workflows",
  "Allow users to approve, reject or edit any data structure",
  "Seamlessly integrate with your existing automations",
];

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const Login = ({ type = "login" }) => {
  const navigate = useNavigate();

  // const location = useLocation();
  // const urlRef = getUrlParameter("ref", location);
  // const via = Cookies.get("via") || getUrlParameter("via", location);
  // const ref = Cookies.get("ref") || getUrlParameter("ref", location);

  const [isSendingPasswordEmail, setIsSendingPasswordEmail] = useState(false);

  const [isFetching, setIsFetching] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);

  const [user, setUser] = useRecoilState(rUser);

  const [state, setState] = useState({
    email: "",
    password: "",
    full_name: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (user) {
      navigate("/admin/steps");
    }
  }, []);

  const validate = () => {
    // Validate form values

    let errors = {};

    if (!emailRegex.test(state.email)) {
      errors["email"] = "Valid email required";
    }

    if (state.password.length < 6) {
      errors["password"] = "Please enter a more secure password";
    }

    if (type === "signup") {
      if (state.full_name.length < 3) {
        errors["full_name"] = "Please enter your full name";
      }

      if (state.full_name.trim().split(" ").length < 2) {
        errors["full_name"] = "Please enter your full name";
      }
    }

    setErrors(errors);

    return isEmpty(errors);
  };

  const login = () => {
    // Make login request
    if (validate()) {
      setIsFetching(true);
      let finalData = {
        ...state,
        // referral_source: ref || via,
      };
      apiRequest
        .post(`/${type}/`, finalData)
        .then((response) => {
          handleResponse(response);
        })
        .catch((e) => {
          console.log("ERROR", e);
          const { message } = handleError(e);
          setErrors({ password: "Invalid password" });
          setIsFetching(false);
          errorNotification(message);
        });
    }
  };

  const handleResponse = (response) => {
    const responseData = get(response, "data");

    const error = get(responseData, "error");
    if (error) {
      errorNotification(error);
      setIsFetching(false);
      setErrors({ password: "Invalid email or password" });
    } else {
      const token = get(responseData, "token");

      // const responseType = get(response, ["data", "type"]);
      Cookies.set("accessToken", token, { expires: 30 });
      setIsFetching(false);

      const responseUser = get(response, ["data", "user"]);
      setUser(responseUser);

      if (type === "signup") {
        navigate("/onboarding");
      } else {
        navigate("/admin/steps");
      }
    }
  };

  const inputPadding = window.innerWidth < 800 ? "9px" : "13px";

  return (
    <PublicWrapper>
      {showForgotPassword && (
        <Modal
          minWidth="420px"
          hide={() => setShowForgotPassword(false)}
          header={{
            title: "Forgot Password",
            description: "Enter your email to request a password reset link",
          }}
        >
          <Form
            isFetching={isSendingPasswordEmail}
            submit={() => {
              setIsSendingPasswordEmail(true);
              apiRequest
                .post("/forgot_password/", {
                  email: state.email,
                })
                .then((r) => {
                  const error = get(r, ["data", "error"]);
                  if (error) {
                    errorNotification(error);
                  } else {
                    successNotification("Password reset email sent");
                    setShowForgotPassword(false);
                    setIsSendingPasswordEmail(false);
                    // mixpanel.track("Reset Password");
                  }
                })
                .catch((error) => {
                  console.log("ERROR", error);
                  errorNotification(get(error, "data"));
                  setIsSendingPasswordEmail(false);
                });
            }}
            errors={errors}
            submitText={"Send Password Reset Email"}
            onChange={(k, v) => setState((s) => ({ ...s, [k]: v }))}
            sectionPadding="0px"
            fields={[
              {
                id: "email",
                componentId: "Input",
                label: "Email",
                value: state.email,
              },
            ]}
          />
        </Modal>
      )}

      <Container>
        <LeftContainer>
          <Headline>
            {type === "login" ? "Login To Curator" : "Try Curator Today"}
          </Headline>
          <Text
            data={{
              text: "Add human confirmation to your AI workflows",
              fontSize: 23,
              color: "var(--text-color)",
              margin: "0 0 30px 0",
            }}
          />

          <FeaturesColumn>
            {features.map((f, index) => (
              <Row $alignitems="center" $gap="15px" key={index}>
                <Icon
                  data={{
                    icon: "FiCheck",
                    color: "var(--dark-grey)",
                    size: 16,
                  }}
                />
                <Text
                  data={{
                    text: f,
                    fontSize: 18,
                    color: "var(--dark-grey)",
                  }}
                />
              </Row>
            ))}
          </FeaturesColumn>
        </LeftContainer>

        <RightContainer>
          <FormContainer>
            <Logo src={logo} />

            {type === "signup" && (
              <FormInputWrapper
                label={"Full Name"}
                error={errors.full_name}
                labelStyle="bodyMdMedium"
              >
                <Input
                  data={{
                    placeholder: "Full Name",
                    border: errors.full_name && "1px solid red",
                    padding: inputPadding,
                    borderRadius: "12px",
                    onChange: (v) => setState((s) => ({ ...s, full_name: v })),
                  }}
                />
              </FormInputWrapper>
            )}

            <FormInputWrapper
              label={"Email"}
              error={errors.email}
              labelStyle="bodyMdMedium"
            >
              <Input
                data={{
                  placeholder: "Email",
                  border: errors.email && "1px solid red",
                  padding: inputPadding,
                  borderRadius: "12px",
                  onChange: (v) => setState((s) => ({ ...s, email: v })),
                }}
              />
            </FormInputWrapper>
            <FormInputWrapper
              label={"Password"}
              error={errors.password}
              labelStyle="bodyMdMedium"
            >
              <Input
                data={{
                  label: "Password",
                  placeholder: "Password",
                  type: "password",
                  border: errors.password && "1px solid red",
                  padding: inputPadding,
                  borderRadius: "12px",
                  onChange: (v) => setState((s) => ({ ...s, password: v })),
                }}
              />
            </FormInputWrapper>

            {/* {type === "signup" && (
              <Row $alignitems="center" $gap="10px" $margin="5px 0 0 0">
                <MUICheckbox
                  color="primary"
                  checked={privacyPolicyAccepted}
                  onChange={() =>
                    setPrivacyPolicyAccepted(!privacyPolicyAccepted)
                  }
                  sx={{
                    color: "var(--dark-grey)",
                  }}
                  value={privacyPolicyAccepted}
                  style={{ padding: "0px" }}
                  size="small"
                />

                <div style={{ fontSize: 14, color: "var(--text-color)" }}>
                  I agree to Curator's{" "}
                  <span
                    onClick={() =>
                      window.open(
                        "https://help.curatorapp.ai/en/articles/8049500-frontly-privacy-policy"
                      )
                    }
                    style={{
                      color: colors.primary,
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                  >
                    Privacy Policy
                  </span>
                </div>
              </Row>
            )} */}

            <Button
              data={{
                text: type === "signup" ? "Create Account" : "Login",
                onClick: login,
                width: "100%",
                padding: inputPadding,
                borderRadius: "12px",
                // disabled: !privacyPolicyAccepted && type === "signup",
                size: "large",
                margin: "5px 0 0 0",
                isFetching,
              }}
            />

            <Row $justifycontent="center" $gap="10px" $margin="10px 0 0 0">
              <Text
                data={{
                  text:
                    type === "login"
                      ? "Don't have an account?"
                      : "Already have an account?",
                  color: colors.grey3,
                  fontSize: 14,
                }}
              />
              <Text
                data={{
                  text: type === "login" ? "Sign Up" : "Login",
                  color: colors.primary,
                  fontSize: 14,
                  fontWeight: 600,
                  onClick: () =>
                    navigate(type === "login" ? "/signup" : "/login"),
                }}
              />
            </Row>
          </FormContainer>
          <Text
            data={{
              text: "Forgot password?",
              onClick: () => setShowForgotPassword(true),
              fontStyle: "bodyLg",
              margin: "25px 0 15px 0",
              color: "#ffffff99",
            }}
          />
        </RightContainer>
      </Container>
    </PublicWrapper>
  );
};

export default Login;

const Logo = styled.img`
  height: 38px;
  object-fit: contain;
  margin-bottom: 10px;
`;

const PublicWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  background: var(--background);
  font-family: "Manrope", "Roboto", sans-serif;
  @media (max-width: 1000px) {
    align-items: flex-start;
  }
`;

const Container = styled.div`
  padding: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 150px;
  @media (max-width: 1000px) {
    flex-direction: column;
    gap: 40px;
    padding: 20px;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 550px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FeaturesColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const Headline = styled.div`
  font-size: 60px;
  font-weight: 500;
  color: var(--text-color);
  margin-bottom: 20px;
  @media (max-width: 1000px) {
    font-size: 42px;
  }
`;

const FormContainer = styled.div`
  background: var(--card-background);
  padding: 40px;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  width: 390px;
  gap: 16px;
  @media screen and (max-width: 800px) {
    width: 100%;
    gap: 15px;
    padding: 25px;
  }
`;
