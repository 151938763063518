import MDSpinner from "react-md-spinner";
import colors from "utils/colors";

const Spinner = ({ color, size, padding = "0px" }) => (
  <div style={{ padding }} id="spinner">
    <MDSpinner singleColor={color || colors.darkGrey} size={size || 30} />
  </div>
);

export default Spinner;
