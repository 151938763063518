import { Button, Dropdown, Spinner, Text } from "components";
import { rConfirmationModalData, rUser } from "utils/recoil";
import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import AdminWrapper from "components/AdminWrapper";
import { apiRequest } from "utils/apiRequests";
import colors from "utils/colors";
import { get } from "lodash";
import styled from "styled-components";
import { successNotification } from "utils/notification";
import useMixpanel from "utils/useMixpanel";
import { useNavigate } from "react-router-dom";

const StepsList = () => {
  const user = useRecoilValue(rUser);
  const [steps, setSteps] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const setConfirmationModalData = useSetRecoilState(rConfirmationModalData);

  const navigate = useNavigate();

  const getSteps = async () => {
    setIsFetching(true);
    const stepsRes = await apiRequest.get("/steps/");
    setSteps(get(stepsRes, "data", []));
    setIsFetching(false);
  };

  useEffect(() => {
    if (user && steps.length === 0) {
      getSteps();
    }
  }, [user]);

  const createNewStep = async () => {
    setIsCreating(true);
    const stepRes = await apiRequest.post("/create_step/");
    const newStepId = get(stepRes, ["data", "id"]);
    setIsCreating(false);
    navigate(`/admin/step/${newStepId}`);
  };

  const deleteStep = (id) => {
    setConfirmationModalData({
      title: "Delete Step",
      text: "Are you sure you want to delete this step?",
      confirmText: "Delete",
      cancelText: "Cancel",
      isFetching: isFetching,
      confirm: () => {
        setIsFetching(true);
        apiRequest.delete("/step/", { data: { id } }).then(() => {
          successNotification("Step deleted");
          navigate("/admin/steps/");
          setIsFetching(false);
          setSteps(steps.filter((s) => s.id !== id));
        });
      },
    });
  };

  // Track mixpanel
  const { track } = useMixpanel();
  useEffect(() => {
    track("Steps List");
  }, []);

  return (
    <AdminWrapper
      title="Steps"
      buttons={[
        {
          text: "Create Step",
          onClick: createNewStep,
          size: "large",
          icon: "FiPlus",
          type: "primary",
          isFetching: isCreating,
        },
      ]}
    >
      <Container>
        {isFetching && <Spinner color={colors.primary} />}
        {!isFetching && (
          <>
            <List
              items={steps.map((p) => (
                <Step data={p} onDelete={() => deleteStep(p.id)} />
              ))}
              emptyText="You haven't created any steps yet. Click 'Create Step' to create your first step."
            />
            {steps.length === 0 && (
              <LearnContainer>
                <Text
                  data={{
                    text: "Learn how to use Curator",
                    fontSize: 24,
                    fontWeight: 600,
                  }}
                />
                <Button
                  data={{
                    text: "Watch Tutorial (7 min)",
                    icon: "FiVideo",
                    size: "large",
                    onClick: () =>
                      window.open("https://youtu.be/9_AidpOnoj4", "_blank"),
                  }}
                />
              </LearnContainer>
            )}
          </>
        )}
      </Container>
    </AdminWrapper>
  );
};

export default StepsList;

const LearnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.inputBorder};
  border-radius: 10px;
  padding: 40px;
  background: white;
  gap: 20px;
  margin-top: 10px;
`;

const Container = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 100%;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  cursor: pointer;
  background: white;
  padding: 20px;
`;

const Step = ({ data, onDelete }) => {
  const navigate = useNavigate();

  return (
    <StepContainer onClick={() => navigate(`/admin/step/${data.id}`)}>
      <div>
        <Text
          data={{
            text: data.name,
            fontSize: 20,
            fontWeight: 700,
            cursor: "pointer",
            margin: "0 0 10px 0",
          }}
        />
        <Text
          data={{
            text: `${data.session_count} sessions`,
            size: 14,
            color: "var(--light-grey)",
            cursor: "pointer",
          }}
        />
      </div>
      <Dropdown
        data={{
          icon: {
            icon: "FiMoreHorizontal",
            size: 25,
            color: colors.grey,
            hover: true,
          },
          options: [
            {
              label: "Delete",
              icon: "FiTrash",
              onClick: onDelete,
            },
          ],
        }}
      />
    </StepContainer>
  );
};

export const List = ({ items = [], emptyText = "No Items" }) => {
  if (items.length === 0) {
    return <Text data={{ text: emptyText, fontSize: 20 }} />;
  }

  return (
    <ListContainer>
      {items.map((item, i) => (
        <ItemContainer key={i}>{item}</ItemContainer>
      ))}
    </ListContainer>
  );
};

const ListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
`;

const ItemContainer = styled.div`
  border: 1px solid ${colors.inputBorder};
  cursor: pointer;
  border-radius: 10px;
  background: white;
  overflow: hidden;
`;
