import { convertJsonToSchema, sortFields } from "utils/utils";
import { useEffect, useState } from "react";

import Cookies from "js-cookie";
import { Loader } from "components";
import { SessionContent } from "./SessionContent";
import { apiRequest } from "utils/apiRequests";
import { errorNotification } from "utils/notification";
import { get } from "lodash";
import { rAccount } from "utils/recoil";
import styled from "styled-components";
import useMixpanel from "utils/useMixpanel";
import { useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";

const LiveSession = () => {
  const { id } = useParams();
  const [session, setSession] = useState(null);
  const [originalSession, setOriginalSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sessionError, setSessionError] = useState(null);

  const setAccount = useSetRecoilState(rAccount);

  // Track mixpanel
  const { track } = useMixpanel();
  useEffect(() => {
    track("Live Session", {
      session_id: id,
    });
  }, []);

  const accessToken = Cookies.get("accessToken");

  useEffect(() => {
    setLoading(true);
    const endpoint = accessToken ? "session" : "session_anonymous";
    apiRequest.get(`/${endpoint}/?id=${id}`).then((res) => {
      const responseData = get(res, "data", null);
      const error = get(responseData, "error", null);

      if (error) {
        errorNotification(error);
        setLoading(false);
        setSessionError(error);
        return;
      } else {
        setSession(responseData);
        setOriginalSession(responseData);
        setLoading(false);

        const primaryColor = get(responseData, "account.primary_color", null);
        const backgroundColor = get(
          responseData,
          "account.background_color",
          null
        );

        setAccount({
          primary_color: primaryColor,
          background_color: backgroundColor,
        });
      }
    });
  }, [id]);

  // Loading state
  if (loading) {
    return <Loader />;
  }

  const sessionData = get(session, "data", null);

  // This should not change during the session
  const originalSessionData = get(originalSession, "data", null);

  const stepConfig = get(session, "step_config", null);
  const step = get(session, "step", null);

  const rootSorting = get(stepConfig, ["_root", "sorting"], []);

  const schema = get(convertJsonToSchema(originalSessionData), "keys");

  const sortedSchema = sortFields({
    schema,
    sortingArray: rootSorting,
    objectKey: "key",
  });

  // Render data editor
  return (
    <Container>
      <SessionContent
        session={session}
        sessionData={sessionData}
        stepConfig={stepConfig}
        step={step}
        sessionError={sessionError}
        schema={sortedSchema}
        setSession={setSession}
        sessionId={id}
      />
    </Container>
  );
};

export default LiveSession;

const Container = styled.div`
  padding: 30px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  background: var(--live-background);
`;
