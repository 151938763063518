import { getPixels, parseBoolean } from "utils/utils";

import colors from "utils/colors";
import styled from "styled-components";

const SwitchWrapper = styled.div`
  min-width: ${(p) => p.backgroundWidth};
  max-width: ${(p) => p.backgroundWidth};
  cursor: pointer;
  border-radius: 100px;
  border: 1px solid ${(p) => (p.$active ? colors.primary : "var(--divider)")};
  padding: 1px;
  display: flex;
  justify-content: ${(p) => (p.$active ? "flex-end" : "flex-start")};
  transition: 0.4s;
  background: ${(p) => (p.$active ? colors.primary : "var(--divider)")};

  ${(p) =>
    p.disabled &&
    `background: var(--divider); cursor: not-allowed; border: 1px solid var(--divider);`}

  ${(p) =>
    !p.disabled &&
    `
      &:hover {
        filter: brightness(90%);
      }
  `}
`;

const SwitchBall = styled.div`
  background: white;
  border-radius: 100px;
  height: ${(p) => p.ballHeight};
  min-width: ${(p) => p.ballHeight};
  max-width: ${(p) => p.ballHeight};
  padding: 1px;
`;

const Switch = ({ data }) => {
  const { disabled, value, onChange } = data;

  const isActive = parseBoolean(value);

  // Handle Switch Ball Height
  let ballHeight = data.ballHeight || 12;
  if (ballHeight < 10) {
    ballHeight = 10;
  }
  const resolvedBallHeight = getPixels(ballHeight);

  let backgroundWidth = data.backgroundWidth || 28;
  if (backgroundWidth < 28) {
    backgroundWidth = 28;
  }
  const resolvedBackgroundWidth = getPixels(backgroundWidth);

  return (
    <SwitchWrapper
      backgroundWidth={resolvedBackgroundWidth}
      disabled={disabled}
      onClick={
        disabled
          ? null
          : (e) => {
              e.stopPropagation();
              onChange(!isActive);
            }
      }
      $active={isActive}
    >
      <SwitchBall $active={isActive} ballHeight={resolvedBallHeight} />
    </SwitchWrapper>
  );
};

export default Switch;
