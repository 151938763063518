import * as Components from "components";

import { Button, Icon, Text } from "components";
import { get, startCase } from "lodash";

import colors from "utils/colors";
import styled from "styled-components";

const DataGrid = ({ data }) => {
  const columns = get(data, "columns", []);
  const rows = get(data, "value", []);

  const addRow = () => {
    data.onChange([...rows, get(data, "newObject", {})]);
  };

  const updateRow = (index, k, v) => {
    data.onChange(
      rows.map((r, i) => {
        if (i === index) {
          return { ...r, [k]: v };
        }
        return r;
      })
    );
  };

  // Make this toggle-able?
  const showDelete = true;

  return (
    <>
      <TableBorderContainer>
        <TableContainer>
          {columns.map((column) => {
            const FormInput = get(Components, column.componentId);

            return (
              <Column width={column.width}>
                <TableHeader>
                  <Text
                    data={{
                      text: startCase(column.label || column.key),
                      fontStyle: "headingXs",
                      cursor: "pointer",
                    }}
                  />
                </TableHeader>
                {rows.map((row, rowIndex) => {
                  const disabled =
                    row.disabled ||
                    get(column, "disableOperators", []).includes(
                      get(row, "operator")
                    );

                  const hideBottomBorder = rowIndex === rows.length - 1;

                  return (
                    <CellContainer hideBottomBorder={hideBottomBorder}>
                      <FormInput
                        data={{
                          ...column,
                          excludedSources: get(data, "excludedSources", []),
                          dynamicSources: get(data, "dynamicSources", []),
                          previousSteps: get(data, "previousSteps"),
                          value: get(row, column.key),
                          border: "0px",
                          disabled,
                          padding:
                            column.componentId === "Select"
                              ? "0px 0px 0px 6px"
                              : "4px",
                          onChange: (v) => updateRow(rowIndex, column.key, v),
                        }}
                      />
                    </CellContainer>
                  );
                })}
              </Column>
            );
          })}

          {showDelete && rows.length > 0 && (
            <DeleteColumn>
              <TableHeader></TableHeader>
              {rows.map((row, rowIndex) => (
                <CellContainer style={{ padding: "5px" }}>
                  <Icon
                    data={{
                      icon: "FiTrash",
                      hover: true,
                      onClick: () =>
                        data.onChange(rows.filter((r, ri) => rowIndex !== ri)),
                      color: colors.grey3,
                      size: 18,
                    }}
                  />
                </CellContainer>
              ))}
            </DeleteColumn>
          )}
        </TableContainer>

        {rows.length === 0 && (
          <Text
            data={{
              text: "No Data",
              fontStyle: "bodySm",
              cursor: "pointer",
              padding: "8px 5px 8px 8px",
            }}
          />
        )}
      </TableBorderContainer>

      <Button
        data={{
          text: "Add",
          onClick: addRow,
          size: "small",
          type: "basic",
          icon: "FiPlus",
          margin: "5px 0 0 0",
        }}
      />
    </>
  );
};

export default DataGrid;

const TableBorderContainer = styled.div`
  border: 1px solid var(--divider);
  border-radius: 10px;
  overflow: hidden;
`;

const TableContainer = styled.div`
  display: flex;
`;

const DeleteColumn = styled.div`
  width: 30px;
`;

const Column = styled.div`
  width: ${(p) => p.width || "100px"};
  flex: 1;
  border-right: 1px solid var(--divider);
`;

const CellContainer = styled.div`
  height: 34px;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-bottom: 1px solid var(--divider);
  ${(p) => p.hideBottomBorder && "border-bottom: none;"}
`;

const TableHeader = styled.div`
  font-weight: 600;
  font-size: 12px;
  height: 34px;
  display: flex;
  align-items: center;
  padding: 5px 5px 5px 8px;
  border-bottom: 1px solid var(--divider);
`;
