import { Button, EditableText, Form, Icon, Row, Text } from "components";
import { get, isEmpty, isEqual, startCase } from "lodash";
import { getPixels, safeArray } from "utils/utils";
import { rEditorState, rHoverPath } from "utils/recoil";
import { useRecoilState, useSetRecoilState } from "recoil";

import BasicForm from "./BasicForm";
import HierarchyEditor from "components/HierarchyEditor";
import { apiRequest } from "utils/apiRequests";
import colors from "utils/colors";
import { errorNotification } from "utils/notification";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export const SessionContent = ({
  isAdmin,
  session,
  sessionData,
  stepConfig,
  updateStep,
  schema,
  setSession,
  sessionError,
  sessionId,
  step, // admin only
}) => {
  const pageWidth = get(step, "pageWidth", 800);

  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  const [activeDataPath, setActiveDataPath] = useState([]);

  const [redirectMessage, setRedirectMessage] = useState(null);

  const setEditorState = useSetRecoilState(rEditorState);

  const [hoverPath, setHoverPath] = useRecoilState(rHoverPath);

  const stepFields = safeArray(step, "fields");

  const hasFields = stepFields.length > 0;

  const confirmAction = get(step, "confirm_action", null);
  const denyAction = get(step, "deny_action", null);

  const handleRedirect = (actionType) => {
    const matchingAction = get(step, actionType, null);

    const redirectType = get(matchingAction, "redirect_type", null);

    if (redirectType === "url") {
      const redirectUrl = get(matchingAction, "redirect_url", null);
      window.location.href = redirectUrl;
    } else if (redirectType === "dashboard") {
      navigate("/dashboard");
    } else if (redirectType === "message") {
      setRedirectMessage(get(matchingAction, "redirect_message", null));
    }
  };

  const handleSubmit = (actionType) => {
    const matchingAction = get(step, actionType, null);

    // Don't run action in admin
    if (!matchingAction || isEmpty(matchingAction)) {
      errorNotification(`Error - ${startCase(actionType)} is not configured`);
      return null;
    }

    // Don't run action in admin
    if (isAdmin) {
      errorNotification("This won't work in the admin setup mode");
      return null;
    }

    setSubmitting(true);

    const endpoint = get(matchingAction, "endpoint", null);

    // 1. Send result via our API to their Endpoint
    apiRequest
      .post("/handle_result/", {
        id: sessionId,
        data: sessionData,
        action_type: actionType,
      })
      .then((res) => {
        setSubmitting(false);

        // Run redirect after response
        if (endpoint) {
          handleRedirect(actionType);
        }
      });

    // Run redirect instantly?
    if (!endpoint) {
      handleRedirect(actionType);
    }
  };

  const account = get(session, "account", null);
  const logo = get(account, "logo", null);

  // Error message
  if (sessionError) {
    return (
      <RedirectContent $maxWidth={pageWidth}>
        {logo && <Logo src={logo} />}
        <RedirectMessage>{sessionError}</RedirectMessage>
        {get(session, "has_user_sessions") && (
          <Button
            data={{
              text: "Go To Dashboard",
              onClick: () => navigate("/dashboard"),
              size: "large",
              type: "basic",
              margin: "30px 0 0 0",
            }}
          />
        )}
      </RedirectContent>
    );
  }

  // Redirect message
  if (redirectMessage) {
    return (
      <RedirectContent $maxWidth={pageWidth}>
        {logo && <Logo src={logo} />}
        <RedirectMessage>{redirectMessage}</RedirectMessage>
        {get(session, "has_user_sessions") && (
          <Button
            data={{
              text: "Go To Dashboard",
              onClick: () => navigate("/dashboard"),
              size: "large",
              type: "basic",
              margin: "30px 0 0 0",
            }}
          />
        )}
      </RedirectContent>
    );
  }

  const rootPath = activeDataPath || ["_root"];
  const rootPathJoined = rootPath
    .filter((p) => typeof p !== "number")
    .join(".");

  // Render editor
  return (
    <Content $maxWidth={pageWidth}>
      {logo && <Logo src={logo} />}
      <Row
        $justifycontent="space-between"
        $alignitems="center"
        $margin="0 0 40px 0"
        $gap="20px"
      >
        <div style={{ width: "100%" }}>
          {get(session, "has_user_sessions") && (
            <Row
              $gap="5px"
              $alignitems="center"
              $margin="0 0 5px 0"
              onClick={() => navigate("/dashboard")}
            >
              <Icon
                data={{
                  icon: "FiArrowLeft",
                  size: 20,
                  margin: "1px 0 0 0",
                  hover: true,
                }}
              />
              <Text
                data={{
                  text: "All Sessions",
                  cursor: "pointer",
                  color: "#727272",
                }}
              />
            </Row>
          )}
          <EditableText
            editable={isAdmin}
            fontSize={26}
            fontWeight={600}
            value={get(session, "title")}
            placeholder="Session Title"
            onChange={(v) => updateStep("title", v)}
            minWidth="100%"
          />
        </div>

        <Row $gap="10px">
          {denyAction && (
            <Button
              data={{
                text: get(denyAction, "label", "Deny") || "Deny",
                size: "large",
                type: "basic",
                isFetching: submitting,
                onClick: () => handleSubmit("deny_action"),
              }}
            />
          )}

          <Button
            data={{
              text: get(confirmAction, "label", "Confirm") || "Confirm",
              size: "large",
              backgroundColor: "var(--primary)",
              isFetching: submitting,
              onClick: () => handleSubmit("confirm_action"),
            }}
          />
        </Row>
      </Row>

      {hasFields && (
        <WhiteCard>
          <BasicForm
            isAdmin={isAdmin}
            value={sessionData}
            fields={stepFields}
            onChange={(k, v) => {
              setSession({
                ...session,
                data: {
                  ...sessionData,
                  [k]: v,
                },
              });
            }}
          />
        </WhiteCard>
      )}

      {sessionData && !isEmpty(sessionData) && !hasFields && (
        <WhiteCard
          onMouseOver={() => setHoverPath(rootPathJoined)}
          onMouseLeave={() => setHoverPath(null)}
          $active={isAdmin && isEqual(rootPathJoined, hoverPath)}
          onClick={(e) => {
            if (isAdmin) {
              e.stopPropagation();
              setEditorState({
                activePath: rootPath,
                anchorElement: e.currentTarget,
                showAdvanced: false,
              });
            }
          }}
        >
          <HierarchyEditor
            isAdmin={isAdmin}
            config={stepConfig}
            schema={schema}
            data={sessionData}
            activeDataPath={activeDataPath}
            setActiveDataPath={setActiveDataPath}
            onChange={(v) => {
              setSession({ ...session, data: v });
            }}
          />
        </WhiteCard>
      )}
    </Content>
  );
};

const RedirectContent = styled.div`
  width: 100%;
  max-width: ${(p) => getPixels(p.$maxWidth || 800)};
  margin: 0 auto;
  padding: 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`;

const Content = styled.div`
  width: 100%;
  max-width: ${(p) => getPixels(p.$maxWidth || 800)};
  margin: 0 auto;
`;

const Logo = styled.img`
  height: 42px;
  width: 150px;
  object-fit: contain;
  margin-bottom: 30px;
  object-position: left;
`;

const RedirectMessage = styled.div`
  font-size: 35px;
  font-weight: 600;
  text-align: center;
`;

const WhiteCard = styled.div`
  background: white;
  padding: 25px;
  border-radius: 10px;
  border: 1px solid ${colors.inputBorder};
  ${(p) => p.$active && `border: 1px solid blue;`}
`;
