import { getFieldStyles, getPixels } from "utils/utils";

import { Icon } from "components";
import styled from "styled-components";
import { useState } from "react";

const Image = ({ data }) => {
  const { objectFit, height, width, value, inputHeight, ...rest } = data;

  const [error, setError] = useState(false);

  if (!value || error) {
    return (
      <ImageFrame
        height={getPixels(height || 100)}
        width={getPixels(width || 200)}
        {...rest}
      >
        <Icon
          data={{
            icon: "FiImage",
            color: "#727272",
            size: "34px",
          }}
        />
      </ImageFrame>
    );
  }

  return (
    <StyledImage
      {...rest}
      objectFit={objectFit}
      height={getPixels(height || 100)}
      width={getPixels(width || 200)}
      src={value}
      onError={() => setError(true)}
    />
  );
};

export default Image;

const ImageFrame = styled.div`
  ${(p) => getFieldStyles(p)};
  height: ${(p) => p.height};
  width: ${(p) => p.width};
  background: #f5f5f5;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImage = styled.img`
  height: ${(p) => p.height};
  width: ${(p) => p.width};
  object-fit: ${(p) => p.objectFit || "cover"};
  border-radius: 10px;
  border: 1px solid var(--divider);
  overflow: hidden;
`;
