import { Breadcrumb, Button, Icon, Row, Text } from "components";
import { useEffect, useState } from "react";

import Modal from "@mui/material/Modal";
import ReactDOM from "react-dom";
import { get } from "lodash";
import { getPixels } from "utils/utils";
import styled from "styled-components";
import useWindowSize from "utils/useWindowSize";

const Content = styled.div`
  padding: ${(p) => p.padding};
  overflow-y: auto;
  max-height: ${(p) => p.maxHeight};
  border-radius: 0 0 12px 12px;
`;

const Footer = styled.div`
  border-top: 1px solid var(--divider);
  padding: 15px 15px 15px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 70px;
  gap: 13px;
`;

const ModalContainer = styled.div`
  background: ${(p) => p.background || "var(--background)"};
  border-radius: ${(p) => p.$borderradius};
  min-width: ${(p) => p.minWidth};
  max-width: ${(p) => p.maxWidth};
  max-height: ${(p) => p.maxHeight};
  &:focus-visible {
    outline: none;
  }

  @media screen and (max-width: 800px) {
    min-width: calc(100% - 20px);
  }

  z-index: ${(p) => p.zIndex || 9999};
`;

const SimpleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0px 20px;
  border-radius: 12px 12px 0 0;
`;

const SimpleTitle = styled.div`
  font-size: ${(p) => p.titleFontSize || "20px"};
  font-weight: 600;
  flex: 1;
  color: var(--text-color);
`;

const XSpacer = styled.div`
  width: 25px;
`;

const FullScreenModal = ({
  children,
  hide,
  header = {},
  background = "var(--background)",
  contentPadding = "0px 20px 20px 20px",
  minWidth = "600px",
  buttons = [],
  titleFontSize = "20px",
  modalPreview = false,
  borderRadius = "12px",
  darkMode = false,
  zIndex = 9999,
}) => {
  const [modalRoot, setModalRoot] = useState(null);

  useEffect(() => {
    setModalRoot(document.getElementById("modal-portal-root"));
  }, []);

  // header params
  const { title, description, onTitleChange, titleIsEditable, breadcrumbs } =
    header;

  const headerButtons = get(header, "buttons", []);

  const verticalPadding = 40;

  const showFooter = buttons.length > 0;
  const footerHeight = showFooter ? 70 : 0;

  const { height: windowHeight, width: windowWidth } = useWindowSize();

  const maxHeight = getPixels(windowHeight - verticalPadding);
  const contentMaxHeight = getPixels(
    windowHeight - verticalPadding - 100 - footerHeight
  );

  const modalProps = modalPreview
    ? {}
    : {
        open: true,
        onClose: hide,
        style: {
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      };

  const maxWidthNum = windowWidth - 60 - (modalPreview ? 300 : 0);

  const resolvedMinWidth =
    parseInt(minWidth) > maxWidthNum ? getPixels(maxWidthNum) : minWidth;

  const modalContent = (
    <ModalContainer
      zIndex={zIndex}
      $borderradius={borderRadius}
      minWidth={
        parseInt(minWidth) > maxWidthNum ? getPixels(maxWidthNum) : minWidth
      }
      maxWidth={resolvedMinWidth}
      maxHeight={maxHeight}
      background={background}
      modalPreview={modalPreview}
    >
      <SimpleHeader background={background}>
        <div>
          {breadcrumbs ? (
            <Breadcrumb items={breadcrumbs} whiteText={darkMode} />
          ) : (
            <SimpleTitle
              titleFontSize={titleFontSize}
              color={darkMode && "white"}
            >
              {title}
            </SimpleTitle>
          )}

          <Text
            data={{
              text: description,
              fontStyle: "bodyLg",
              margin: "10px 0 10px 0",
              color: "var(--light-grey)",
            }}
          />
        </div>

        <Row align="center" $gap="15px">
          {headerButtons.map((b, i) => (
            <Button data={b} />
          ))}

          <XSpacer>
            <Icon
              data={{
                icon: "FiX",
                onClick: hide,
                color: "var(--light-grey)",
                hover: true,
                size: 24,
              }}
            />
          </XSpacer>
        </Row>
      </SimpleHeader>

      <Content
        background={background}
        padding={contentPadding}
        maxHeight={contentMaxHeight}
      >
        {children}
      </Content>

      {showFooter && (
        <Footer>
          {buttons.map((b, i) => (
            <Button data={b} />
          ))}
        </Footer>
      )}
    </ModalContainer>
  );

  // PREVIEW ONLY - NO WRAPPER
  if (modalPreview) {
    return modalContent;
  }

  if (!modalRoot) return null;

  // FULL MODAL
  return ReactDOM.createPortal(
    <Modal {...modalProps}>{modalContent}</Modal>,
    modalRoot
  );
};

export default FullScreenModal;
