import { get, startCase } from "lodash";

import FormField from "components/FormField";
import { rFormFieldsState } from "utils/recoil";
import styled from "styled-components";
import { useRecoilState } from "recoil";

const BasicForm = ({ isAdmin, value, fields, onChange }) => {
  const [formFieldsState, setFormFieldsState] =
    useRecoilState(rFormFieldsState);

  return (
    <Container $gridcolumns={2}>
      {fields.map((field, fieldIndex) => {
        const componentId = get(field, "componentId", "Input");
        let columnSpan = get(field, "columnSpan", 1);

        if (["TextArea", "Image"].includes(componentId)) {
          columnSpan = 2;
        }

        return (
          <FormField
            key={fieldIndex}
            data={{
              ...field,
              isAdmin,
              columnSpan,
              padding: "16px",
              inputHeight: "48px",
              value: get(value, field.key),
              label: get(field, "label", startCase(field.id)),
              componentId: get(field, "componentId", "Input"),
              labelFontSize: 16,
              labelFontWeight: 600,
              handleFieldClick: (anchor) =>
                setFormFieldsState({
                  ...formFieldsState,
                  anchorElement: anchor,
                  activeFieldIndex: fieldIndex,
                }),
              // error: get(errors, field.id),
              onChange: (value) => onChange(field.key, value),
            }}
          />
        );
      })}
    </Container>
  );
};

export default BasicForm;

const Container = styled.div`
  display: grid;
  gap: 20px;
  width: 100%;
  grid-template-columns: repeat(${(p) => parseInt(p.$gridcolumns)}, 1fr);
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
