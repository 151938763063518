import mixpanel from "mixpanel-browser";
import { rIsMixpanelInitialized } from "./recoil";
import { useRecoilValue } from "recoil";

const useMixpanel = () => {
  const isMixpanelInitialized = useRecoilValue(rIsMixpanelInitialized);

  const track = (event, properties) => {
    if (isMixpanelInitialized) {
      mixpanel.track(event, properties);
    }
  };

  return { track };
};

export default useMixpanel;
