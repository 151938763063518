import { Button, Form, Row, Text } from "components";
import { apiRequest, webUrl } from "utils/apiRequests";
import { errorNotification, successNotification } from "utils/notification";
import { get, isEmpty } from "lodash";
import { useEffect, useState } from "react";

import AdminWrapper from "components/AdminWrapper";
import colors from "utils/colors";
import { getUrlParameter } from "utils/utils";
import { rAccount } from "utils/recoil";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import useMixpanel from "utils/useMixpanel";
import { useRecoilState } from "recoil";

const Settings = () => {
  // Track mixpanel
  const { track } = useMixpanel();
  useEffect(() => {
    track("Settings");
  }, []);

  const [account, setAccount] = useRecoilState(rAccount);
  const [accountChanges, setAccountChanges] = useState({});
  const [slackFetched, setSlackFetched] = useState(false);

  const save = () => {
    apiRequest.post("/account/", accountChanges).then((res) => {
      successNotification("Saved");
      setAccountChanges({});
    });
  };

  const location = useLocation();

  const slackCode = getUrlParameter("code", location);

  // TODO - Make real
  // const slackState = getUrlParameter("state", location);
  const slackState = "testing12341234";

  useEffect(() => {
    if (!slackFetched && slackCode && slackState && get(account, "id")) {
      setSlackFetched(true);
      apiRequest
        .post("/slack/auth/", { code: slackCode, state: slackState })
        .then((res) => {
          const success = get(res, ["data", "success"], false);
          if (success) {
            successNotification("Slack connected");
            setAccount({
              ...account,
              slack_integrated: true,
            });
          } else {
            errorNotification("Failed to connect to Slack");
          }
        });
    }
  }, [account, slackCode, slackState, slackFetched]);

  const slackIntegrated = get(account, "slack_integrated", false);

  return (
    <AdminWrapper title="Settings">
      <Row $gap={30} $wrap="wrap">
        <Container>
          <Text
            data={{
              text: "Branding",
              fontSize: 22,
              fontWeight: 600,
              margin: "0 0 20px 0",
            }}
          />
          <Form
            value={account}
            submitText="Save Changes"
            submit={isEmpty(accountChanges) ? null : save}
            fields={[
              {
                id: "logo",
                label: "Logo",
                componentId: "ImageUpload",
                value: get(account, "logo", ""),
              },
              {
                id: "primary_color",
                label: "Primary Color",
                componentId: "ColorPicker",
                value: get(account, "primary_color", ""),
              },
              {
                id: "background_color",
                label: "Background Color",
                componentId: "ColorPicker",
                value: get(account, "background_color", ""),
              },
            ]}
            onChange={(k, v) => {
              setAccount({ ...account, [k]: v });
              setAccountChanges({ ...accountChanges, [k]: v });
            }}
          />
        </Container>

        <Container>
          <Text
            data={{
              text: "API Key",
              fontSize: 22,
              fontWeight: 600,
              margin: "0 0 10px 0",
            }}
          />
          <Text
            data={{
              text: "Your API Key is required in your requests to the 'Create Session' endpoint.",
              fontSize: 16,
              fontWeight: 300,
              margin: "0 0 20px 0",
              allowSelect: true,
            }}
          />
          <Text
            data={{
              text: "Click to copy:",
              fontSize: 16,
              fontWeight: 300,
              margin: "0 0 5px 0",
              allowSelect: true,
            }}
          />
          <Text
            data={{
              text: get(account, "api_key", ""),
              fontSize: 18,
              fontWeight: 500,
              color: colors.primary,
              allowSelect: true,
              onClick: () => {
                navigator.clipboard.writeText(get(account, "api_key", ""));
                successNotification("Copied to clipboard");
              },
            }}
          />
        </Container>

        <Container>
          <Text
            data={{
              text: "Custom Domain",
              fontSize: 22,
              fontWeight: 600,
              margin: "0 0 10px 0",
            }}
          />
          <Text
            data={{
              text: "Adding a custom domain requires a paid plan. Contact our team to get started.",
              fontSize: 18,
              fontWeight: 300,
            }}
          />
        </Container>

        <Container>
          <Text
            data={{
              text: "Slack Integration",
              fontSize: 22,
              fontWeight: 600,
              margin: "0 0 10px 0",
            }}
          />
          <Text
            data={{
              text: "Add a Slack integration to your account to receive notifications when a session is created.",
              fontSize: 18,
              fontWeight: 300,
            }}
          />
          {!slackIntegrated && (
            <Button
              data={{
                text: "Add Slack Integration",
                margin: "20px 0 0 0",
                icon: "FiSlack",
                onClick: () => {
                  const clientId = process.env.REACT_APP_SLACK_CLIENT_ID;
                  const redirectUrl = `${webUrl}/admin/settings/?action=slack_confirm`;
                  window.open(
                    `https://slack.com/oauth/v2/authorize?client_id=${clientId}&scope=chat:write,channels:read&redirect_uri=${redirectUrl}`,
                    "_blank"
                  );
                },
              }}
            />
          )}
          {slackIntegrated && (
            <Button
              data={{
                text: "Disconnect Slack",
                margin: "20px 0 0 0",
                onClick: () => {
                  apiRequest.post("/account/", {
                    slack_connection: null,
                  });
                  successNotification("Slack disconnected");
                  setAccount({
                    ...account,
                    slack_integrated: false,
                  });
                },
              }}
            />
          )}
        </Container>
      </Row>
    </AdminWrapper>
  );
};

export default Settings;

const Container = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid ${colors.inputBorder};
  width: 400px;
  align-self: stretch;
`;
