import { get, startCase } from "lodash";

import { DataGrid } from "components";

const KeyValuePairs = ({ data }) => {
  const filters = get(data, "value", []);

  const addFilter = (newFilters) => {
    data.onChange(newFilters);
  };

  const key = data.useLabelAsKey ? "label" : "key";

  const newObject = {
    [key]: "new option",
    value: "new option",
  };

  return (
    <DataGrid
      data={{
        excludedSources: get(data, "excludedSources", []),
        dynamicSources: get(data, "dynamicSources", []),
        previousSteps: get(data, "previousSteps"),
        newObject,
        onChange: (newFilters) => addFilter(newFilters),
        value: filters,
        columns: [
          {
            key: key,
            label: startCase(key),
            width: "100%",
            componentId: "Input",
            value: get(data, key),
          },
          {
            key: "value",
            label: data.valueLabel || "Value",
            width: "100%",
            componentId: "Input",
            value: get(data, "value"),
          },
        ],
      }}
    />
  );
};

export default KeyValuePairs;
