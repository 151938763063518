import { useLocation, useNavigate } from "react-router-dom";

import Button from "./Button";
import Cookies from "js-cookie";
import Dropdown from "./Dropdown";
import Icon from "./Icon";
import Row from "./Row";
import Text from "./Text";
import colors from "utils/colors";
import { get } from "lodash";
import logo from "images/curator-icon-green.png";
import mixpanel from "mixpanel-browser";
import { rUser } from "utils/recoil";
import styled from "styled-components";
import { useSetRecoilState } from "recoil";

const AdminWrapper = ({
  children,
  backFunction,
  backRoute,
  backText,
  title,
  buttons = [],
  menu = [],
  width,
}) => {
  const navigate = useNavigate();

  const setUser = useSetRecoilState(rUser);

  const location = useLocation();

  const items = [
    {
      label: "Steps",
      route: "steps",
      icon: "FiList",
      onClick: () => navigate("/admin/steps/"),
    },
    {
      label: "Users",
      route: "users",
      icon: "FiUsers",
      onClick: () => navigate("/admin/users/"),
    },
    {
      label: "Help",
      route: "resources",
      icon: "FiLifeBuoy",
      onClick: () => navigate("/admin/resources/"),
    },
    {
      label: "Billing",
      icon: "FiCreditCard",
      route: "billing",
      onClick: () => navigate("/admin/billing/"),
    },
    {
      label: "Settings",
      icon: "FiSettings",
      route: "settings",
      onClick: () => navigate("/admin/settings/"),
    },
  ];

  return (
    <AppContainer width={width}>
      <Navigation>
        <Dropdown
          data={{
            component: <AppIcon src={logo} />,
            options: [
              {
                label: "Logout",
                onClick: () => {
                  Cookies.remove("accessToken");
                  navigate("/login/");
                  setUser(null);
                  mixpanel.reset();
                },
              },
            ],
          }}
        />
        {items.map((item, i) => (
          <NavigationItem
            key={i}
            $active={get(location, "pathname").includes(item.route)}
            onClick={item.onClick}
          >
            <Icon
              data={{
                icon: item.icon,
                size: 25,
                color: colors.grey,
                hover: true,
              }}
            />

            <Text
              data={{
                text: item.label,
                size: 12,
                color: colors.grey,
                cursor: "pointer",
              }}
            />
          </NavigationItem>
        ))}
      </Navigation>
      <Content>
        <Row
          $alignitems="center"
          $justifycontent="space-between"
          $margin="0 0 30px 0"
        >
          {(backFunction || backRoute) && (
            <Row
              $alignitems="center"
              $gap="10px"
              onClick={backRoute ? () => navigate(backRoute) : backFunction}
              $margin="0 10px 0 0"
            >
              <Icon
                data={{
                  icon: "FiArrowLeft",
                  color: colors.darkGrey,
                  size: 30,
                  margin: "0 0 0 -4px",
                  hover: true,
                }}
              />
              {backText && (
                <Text
                  data={{
                    text: backText,
                    size: 22,
                    cursor: "pointer",
                    color: "rgba(0,0,0,0.5)",
                  }}
                />
              )}
            </Row>
          )}

          <Text
            data={{
              text: title,
              fontWeight: 700,
              fontSize: 30,
              margin: "0 0 4px 0",
            }}
          />
          <Row $alignitems="center" $justifycontent="space-between" $gap="15px">
            {menu.length > 0 && (
              <Dropdown
                data={{
                  icon: {
                    icon: "FiMoreHorizontal",
                    size: 25,
                    color: colors.grey,
                    margin: "0 15px 0 0",
                    hover: true,
                  },
                  options: menu,
                }}
              />
            )}
            {buttons.map((b, i) => (
              <Button key={i} data={b} style={{ fontSize: "30px" }} />
            ))}
          </Row>
        </Row>
        <Container>{children}</Container>
      </Content>
    </AppContainer>
  );
};

export default AdminWrapper;

const AppContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  border-right: 1px solid ${colors.inputBorder};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  min-width: 100px;
  max-width: 100px;
  padding-top: 30px;
  background: white;
`;

const Content = styled.div`
  position: fixed;
  top: 0;
  left: 100px;
  right: 0px;
  bottom: 0;
  padding: 50px;
  overflow-y: auto;
`;

const NavigationItem = styled.div`
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 80px;
  height: 80px;
  ${(p) => p.$active && `background: rgba(0, 0, 0, 0.05);`}
  &:hover {
    background: rgba(0, 0, 0, 0.02);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  width: 100%;
`;

const AppIcon = styled.img`
  height: 45px;
  width: 45px;
  cursor: pointer;
  margin-bottom: 10px;
`;
