import { Button, Icon, Row, Text } from "components";

import colors from "utils/colors";
import { get } from "lodash";
import { rAccount } from "utils/recoil";
import { safeLower } from "utils/utils";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useState } from "react";

const SetupInstructions = ({ fields }) => {
  const [expandedSection, setExpandedSection] = useState("basic");
  const account = useRecoilValue(rAccount);
  const { id } = useParams();

  const dataObject = fields.reduce((acc, field) => {
    acc[field.key] = "Your real value here";
    return acc;
  }, {});

  return (
    <div>
      <ExpandingCard>
        <HeaderSection onClick={() => setExpandedSection("basic")}>
          <Row $alignitems="center" $gap="10px">
            <Text
              data={{
                text: "Basic Setup",
                fontSize: 20,
                fontWeight: 600,
                cursor: "pointer",
              }}
            />
            <Text
              data={{
                text: "(Standard form fields)",
                fontSize: 20,
                fontWeight: 300,
                cursor: "pointer",
              }}
            />
          </Row>
          <Icon
            data={{
              hover: true,
              icon:
                expandedSection === "basic" ? "FiChevronUp" : "FiChevronDown",
            }}
          />
        </HeaderSection>

        {expandedSection === "basic" && (
          <ExpandedSection>
            <SetupByPlatform
              dataObject={dataObject}
              apiKey={get(account, "api_key")}
              stepId={id}
              platform={get(account, "automation_platform")}
            />
          </ExpandedSection>
        )}
      </ExpandingCard>

      <ExpandingCard style={{ marginTop: "20px" }}>
        <HeaderSection onClick={() => setExpandedSection("advanced")}>
          <Row $alignitems="center" $gap="10px">
            <Text
              data={{
                text: "Advanced Setup",
                fontSize: 20,
                fontWeight: 600,
                cursor: "pointer",
              }}
            />
            <Text
              data={{
                text: "(JSON)",
                fontSize: 20,
                fontWeight: 300,
                cursor: "pointer",
              }}
            />
          </Row>
          <Icon
            data={{
              hover: true,
              icon:
                expandedSection === "advanced"
                  ? "FiChevronUp"
                  : "FiChevronDown",
            }}
          />
        </HeaderSection>
        {expandedSection === "advanced" && (
          <ExpandedSection>
            <Text
              data={{
                text: "Create a session via API to establish the data structure:",
                fontSize: 20,
                fontWeight: 400,
                margin: "0 0 15px 0",
              }}
            />

            <Text
              data={{
                text: "POST - https://api.curatorapp.ai/create_session/",
                fontSize: 20,
                fontWeight: 500,
                margin: "30px 0 15px 0",
                allowSelect: true,
              }}
            />

            <Text
              data={{
                text: "Note: Everything within the 'data' key should be your custom data. This is just an example.",
                fontSize: 16,
                fontWeight: 300,
                margin: "20px 0 15px 0",
                allowSelect: true,
              }}
            />

            <Structure>
              {JSON.stringify(
                {
                  step_id: parseInt(id),
                  api_key: get(account, "api_key"),
                  data: {
                    your_field: "Your custom data here",
                    another_field: "This is some more data",
                  },
                },
                null,
                2
              )}
            </Structure>
          </ExpandedSection>
        )}
      </ExpandingCard>
    </div>
  );
};

export default SetupInstructions;

const SetupByPlatform = ({ platform, stepId, apiKey, dataObject }) => {
  const platformLower = safeLower(platform);

  if (platformLower === "zapier") {
    return (
      <>
        <Text
          data={{
            text: "1. Watch Zapier Tutorial Video",
            fontSize: 18,
            fontWeight: 600,
            margin: "0 0 10px 0",
          }}
        />
        <Button
          data={{
            text: "Watch Zapier Tutorial Video",
            variant: "primary",
            size: "large",
            icon: "FiPlay",
            onClick: () => window.open("https://youtu.be/_duQ1S9fh08"),
          }}
        />
        <Text
          data={{
            text: "2. Add Fields To Your Approval Step",
            fontSize: 18,
            fontWeight: 600,
            margin: "20px 0 10px 0",
          }}
        />
        <Text
          data={{
            text: "Click 'Add Field' in the Fields section of the sidebar to manually define fields for your approval session.",
            fontSize: 18,
            fontWeight: 300,
          }}
        />
        <Text
          data={{
            text: "3. Create a Curator Step in your Zap",
            fontSize: 18,
            fontWeight: 600,
            margin: "20px 0 10px 0",
          }}
        />
        <Text
          data={{
            text: "In Zapier, create a Curator step where you want to trigger human approval.",
            fontSize: 18,
            fontWeight: 300,
          }}
        />
      </>
    );
  } else {
    // OTHER

    return (
      <>
        <Text
          data={{
            text: "1. Watch Curator Demo Video",
            fontSize: 18,
            fontWeight: 600,
            margin: "0 0 10px 0",
          }}
        />
        <Button
          data={{
            text: "Watch Demo Video",
            variant: "primary",
            size: "large",
            icon: "FiPlay",
            onClick: () =>
              window.open("https://www.youtube.com/watch?v=8EN13z18TfI"),
          }}
        />
        <Text
          data={{
            text: "2. Add Fields To Your Approval Step",
            fontSize: 18,
            fontWeight: 600,
            margin: "20px 0 10px 0",
          }}
        />
        <Text
          data={{
            text: "Click 'Add Field' in the Fields section of the sidebar to manually define fields for your approval session.",
            fontSize: 18,
            fontWeight: 300,
          }}
        />
        <Text
          data={{
            text: "3. Trigger Your Curator Approval Session",
            fontSize: 18,
            fontWeight: 600,
            margin: "20px 0 10px 0",
          }}
        />

        <TextSpan>
          Inside your <Bold>{platform}</Bold> automation, trigger an{" "}
          <Bold>HTTP POST</Bold> request to the
          <Bold> https://api.curatorapp.ai/create_session/</Bold> endpoint with
          the following body:
        </TextSpan>
        <Structure>
          {JSON.stringify(
            {
              step_id: parseInt(stepId),
              api_key: apiKey,
              data: dataObject,
            },
            null,
            2
          )}
        </Structure>
      </>
    );
  }
};

const Bold = styled.span`
  font-weight: 600;
`;

const TextSpan = styled.span`
  font-size: 18px;
  font-weight: 300;
`;

const ExpandingCard = styled.div`
  border: 1px solid ${colors.inputBorder};
  border-radius: 10px;
  background: white;
`;

const HeaderSection = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const ExpandedSection = styled.div`
  padding: 20px;
  border-top: 1px solid ${colors.inputBorder};
`;

const Structure = styled.pre`
  font-family: monospace;
  padding: 15px;
  background: white;
  border-radius: 10px;
  font-size: 16px;
  border: 1px solid ${colors.inputBorder};
  word-wrap: break-word;
`;
