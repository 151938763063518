import "react-notifications-component/dist/theme.css";

import { ConfirmationModal, Loader } from "components";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  rAccount,
  rIntercomInitialized,
  rIsFetchingAccount,
  rIsMixpanelIdentified,
  rIsMixpanelInitialized,
  rUser,
} from "utils/recoil";
import { useEffect, useState } from "react";

import Billing from "pages/Billing";
import Cookies from "js-cookie";
import Dashboard from "pages/Dashboard";
import Intercom from "@intercom/messenger-js-sdk";
import Login from "pages/Login";
import Onboarding from "pages/Onboarding";
import { ReactNotifications } from "react-notifications-component";
import Resources from "components/Resources";
import Session from "./pages/Sessions/LiveSession";
import Settings from "pages/Settings";
import StepDetails from "pages/Steps/StepDetails";
import StepsList from "pages/Steps/StepsList";
import Users from "pages/Users";
import { apiRequest } from "./utils/apiRequests";
import { get } from "lodash";
import { getUrlParameter } from "./utils/utils";
import mixpanel from "mixpanel-browser";
import { useRecoilState } from "recoil";

function App() {
  const location = useLocation();

  const path = get(location, "pathname");

  const navigate = useNavigate();

  const magicLink = getUrlParameter("magic", location);
  const magicRoute = magicLink && getUrlParameter("route", location);

  const [isFetchingAccount, setIsFetchingAccount] =
    useRecoilState(rIsFetchingAccount);

  const [account, setAccount] = useRecoilState(rAccount);
  const [user, setUser] = useRecoilState(rUser);
  const accountId = get(account, "id");

  const primaryColor = get(account, "primary_color", null);
  const backgroundColor = get(account, "background_color", null);

  const [hasFetchedAccount, setHasFetchedAccount] = useState(false);

  const [isMixpanelIdentified, setIsMixpanelIdentified] = useRecoilState(
    rIsMixpanelIdentified
  );
  const [isMixpanelInitialized, setIsMixpanelInitialized] = useRecoilState(
    rIsMixpanelInitialized
  );

  const [isIntercomInitialized, setIsIntercomInitialized] =
    useRecoilState(rIntercomInitialized);

  const isPublicRoute =
    path.includes("/session/") ||
    path.includes("/login") ||
    path.includes("/signup");

  useEffect(() => {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
    setIsMixpanelInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Idenitfy User once per session max
  useEffect(() => {
    if (user && isMixpanelInitialized && !isMixpanelIdentified) {
      setIsMixpanelInitialized(true);
      mixpanel.identify(user.email);
      mixpanel.people.set({
        $email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
      });
    }
  }, [user, isMixpanelInitialized]);

  useEffect(() => {
    if (
      !path.includes("onboarding") &&
      user &&
      account &&
      !isIntercomInitialized &&
      !isFetchingAccount &&
      !window.location.host.includes("localhost")
    ) {
      setIsIntercomInitialized(true);
      const intercomObj = {
        app_id: "c6tmpph9",
        account_id: account.id,
        platform: get(account, "automation_platform", null),
        user_id: user.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
        // name: `${user.firstName} ${user.lastName}`, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
        name: user.email,
        email: user.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
        created_at: account.created, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
      };
      Intercom(intercomObj);
    }
  }, [user, account, isFetchingAccount, path]);

  const accessToken = Cookies.get("accessToken");

  useEffect(() => {
    if (!isPublicRoute && !accountId && accessToken && !hasFetchedAccount) {
      setHasFetchedAccount(true);
      setIsFetchingAccount(true);
      apiRequest.get("/account/").then((r) => {
        const responseData = get(r, "data");
        const accountData = get(responseData, "account");
        const userData = get(responseData, "user");
        setAccount(accountData);
        setUser(userData);

        setIsFetchingAccount(false);
      });
    }
  }, [hasFetchedAccount, path]);

  // Redirect user to onboarding if they haven't done it yet
  useEffect(() => {
    if (get(account, "id") && !get(account, "automation_platform")) {
      navigate("/admin/onboarding");
    }
  }, [account]);

  useEffect(() => {
    if (!isPublicRoute && !accountId && !accessToken) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (magicLink) {
      Cookies.set("accessToken", magicLink, { expires: 30 });
      if (magicRoute) {
        navigate(magicRoute);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Preparing for more theme styles
  const stylingObject = {
    "--primary": primaryColor,
    "--live-background": backgroundColor,
    // "--input-border-radius": get(account, "input_border_radius", "10px"),
  };

  useEffect(() => {
    // Dynamically update CSS variable
    Object.keys(stylingObject).forEach((key) => {
      const value = stylingObject[key];
      if (value) {
        document.documentElement.style.setProperty(key, value);
      }
    });
    // theme === "dark" || isAdminPublicPage;
    document.body.classList.toggle("dark-mode", true);
  }, [primaryColor, backgroundColor]);

  // TODO - Improve glitchy fetching logic
  //  || !hasFetchedAccount || !accountId
  if (isFetchingAccount) {
    return <Loader />;
  }

  return (
    <div>
      <div
        id="modal-portal-root"
        style={{ zIndex: 9999, position: "absolute" }}
      ></div>
      <ReactNotifications style={{ zIndex: 2000 }} />
      <Routes>
        {/* ADMIN ROUTES */}
        <Route path="admin" element={<PrivateRoute />}>
          <Route path="step/:id" element={<StepDetails />} />
          <Route path="steps" element={<StepsList />} />
          <Route path="users" element={<Users />} />
          <Route path="billing" element={<Billing />} />
          <Route path="settings" element={<Settings />} />
          <Route path="resources" element={<Resources />} />
          <Route path="onboarding" element={<Onboarding />} />
        </Route>

        <Route path="dashboard" element={<Dashboard />} />

        {/* PUBLIC ROUTES */}
        {/* IF NO DEFAULT PRIVATE OR PUBLIIC PAGES ACCESSIBLE, RENDER LOGIN */}
        <Route path="" element={<Login />} />
        <Route path="login" element={<Login type="login" />} />
        <Route path="signup" element={<Login type="signup" />} />
        <Route path="session/:id" element={<Session />} />
        <Route path="*" element={<Login />} />
      </Routes>
      <ConfirmationModal />
    </div>
  );
}

export default App;

const PrivateRoute = () => {
  const accessToken = Cookies.get("accessToken");

  const getRedirectRoute = () => {
    return "/login";
  };
  if (accessToken) {
    return <Outlet />;
  }
  return <Navigate to={getRedirectRoute()} />;
};
