import { getPixels } from "utils/utils";
import styled from "styled-components";

const Row = styled.div`
  display: flex;
  align-items: ${(p) => p.$alignitems || "flex-start"};
  justify-content: ${(p) => p.$justifycontent || "flex-start"};
  width: ${(p) => p.width || "auto"};
  gap: ${(p) => getPixels(p.$gap || 0)};
  margin: ${(p) => p.$margin || "0px"};
  flex-wrap: ${(p) => p.$wrap || "nowrap"};
`;

export default Row;
