import { atom } from "recoil";

export const rFormFieldsState = atom({
  key: "formFieldsState",
  default: {},
});

export const rEditorState = atom({
  key: "editorState",
  default: {
    activePath: null,
    anchorElement: null,
    showAdvanced: false,
  },
});

export const rHoverPath = atom({
  key: "hoverPath",
  default: null,
});

export const rIsFetchingAccount = atom({
  key: "isFetchingAccount",
  default: false,
});

export const rAccount = atom({
  key: "account",
  default: null,
});

export const rConfirmationModalData = atom({
  key: "confirmationModalData",
  default: null,
});

export const rUser = atom({
  key: "user",
  default: null,
});

export const rIsMixpanelInitialized = atom({
  key: "isMixpanelInitialized",
  default: false,
});

export const rIsMixpanelIdentified = atom({
  key: "isMixpanelIdentified",
  default: false,
});

export const rIntercomInitialized = atom({
  key: "intercomInitialized",
  default: false,
});
