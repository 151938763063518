import { Fragment, useEffect, useState } from "react";
import { MuiDropdown, Text } from "..";

import { ChromePicker } from "react-color";
import { ColorDisplay } from "./utils";
import { get } from "lodash";
// import { Tooltip } from "react-tooltip";
import rgbHex from "rgb-hex";
import styled from "styled-components";

const RecentColors = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 10px;
  margin-top: 5px;
`;

const ColorPicker = ({ data }) => {
  const [hex, setHex] = useState(data.value || data.defaultValue);

  const savedColors = [];
  // const savedColors = get(activeApp, ["styling", "colors"], []);

  useEffect(() => {
    if (get(hex, "rgb")) {
      const { r, g, b, a } = get(hex, "rgb");
      setHex("#" + rgbHex(r, g, b, a));
    }
  }, [data.value, data.defaultValue]);

  const handleChange = (colorObject) => {
    const { r, g, b, a } = get(colorObject, "rgb");
    const newHex = "#" + rgbHex(r, g, b, a);
    setHex(newHex);

    if (data.outputFormat === "object") {
      return data.onChange(colorObject);
    }

    return data.onChange(newHex);
  };

  return (
    <MuiDropdown
      triggerComponent={
        <ColorDisplay color={addTransparency(hex)} darkTheme={data?.darkTheme}>
          &nbsp;
        </ColorDisplay>
      }
    >
      <div
        style={{ padding: savedColors.length > 0 ? "20px" : 0 }}
        id="colorPicker-body"
      >
        {savedColors && savedColors.length > 0 && (
          <Fragment>
            <Text data={{ text: "Saved Colors", fontStyle: "headingSm" }} />
            {/* <RecentColors>
              {savedColors.map((c, index) => (
                <>
                  <Tooltip
                    anchorSelect={`.color-${startCase(c.name)}-${index}`}
                    place="bottom"
                    style={{ zIndex: 9999 }}
                  >
                    {c.name}
                  </Tooltip>
                  <ColorDisplay
                    className={`color-${startCase(c.name)}-${index}`}
                    key={index}
                    color={addTransparency(c.color)}
                    onClick={() => {
                      data.onChange(c.color);
                      setHex(c.color);
                    }}
                    darkTheme={data?.darkTheme}
                  >
                    &nbsp;
                  </ColorDisplay>
                </>
              ))}
            </RecentColors> */}
          </Fragment>
        )}
        <style>{`.chrome-picker { box-shadow: none !important; }`}</style>
        <ChromePicker color={hex} onChange={handleChange} id="colorPicker" />
      </div>
    </MuiDropdown>
  );
};

export default ColorPicker;

export const addTransparency = (hex = "") => {
  if (!hex) {
    return hex;
  }

  const { length } = hex;
  const hasTransparency = length === 9;
  if (hasTransparency) {
    return hex;
  } else {
    return hex + "ff";
  }
};
